myApp.controller('navController', ['$scope', '$location', '$cookies', 'userModel', '$routeParams', 'queueModel', 
	function($scope, $location, $cookies, userModel, $routeParams, queueModel) {

	var user = $cookies.getObject('auth');



	//TYPES OF SEARCH
	if(user.state == "admin" && user.cr == 0)
	{
		$scope.data = {
     	availableOptions: [
       		{value: 'client', text: 'Cliente'},
    		{value: 'PO', text: 'PO'},
    		{value: 'NP', text: 'NP'},
    		{value: 'ecf_erf', text: 'ECF/ERF'},
    		{value: 'proforma', text: 'Proforma'},
    		{value: 'CR', text: 'CR'},
    		{value: 'Produto', text: 'Produto'},
    		{value: 'Fornecedor', text: 'Fornecedor'},
    		{value: 'Week Encomenda', text: 'Week Encomenda'},
    		{value: 'tipo_medidas', text: 'Tipologia'},
     	],
     	};
     	if(!$routeParams.param) {
     	$scope.selected_ = {value: 'PO', text: 'PO'};
     	}
  	}
  	else if(user.state == "preggo" || user.cr == 1)
  	{
  		$scope.data = {
     	availableOptions: [
       		{value: 'client', text: 'Cliente'},
          {value: 'PO', text: 'PO'},
    		{value: 'CR', text: 'CR'},
    		{value: 'NP', text: 'NP'},
    		{value: 'Produto', text: 'Produto'},
    		{value: 'Week Encomenda', text: 'Week Encomenda'},
    		{value: 'tipo_medidas', text: 'Tipologia'},
     	],
     	};
     	if(!$routeParams.param) {
     	$scope.selected_ = {value: 'PO', text: 'PO'};
     	}
  	}
  	else
  	{
  		$scope.data = {
     	availableOptions: [
       		{value: 'client', text: 'Cliente'},
    		{value: 'PO', text: 'PO'},
    		{value: 'NP', text: 'NP'},
    		{value: 'ecf_erf', text: 'ECF/ERF'},
    		{value: 'proforma', text: 'Proforma'},
    		{value: 'Produto', text: 'Produto'},
    		{value: 'Week Encomenda', text: 'Week Encomenda'},
    		{value: 'tipo_medidas', text: 'Tipologia'},
     	],
     	};
     	if(!$routeParams.param) {
     	$scope.selected_ = {value: 'PO', text: 'PO'};
     	}


  	}

  //CHECK IF DEVICE IS LOWER THAN 401
  var window_width = $( window ).width();
  if(window_width <= 420)
  {
    $scope.mobile_sigla = true;
  }
  else
  {
    $scope.mobile_sigla = false;
  }
  
	//variables
	angular.extend($scope, {
		user: userModel.getUserObject(),
		navUrl: userModel.getUserState(),
		Search_type : "Cliente",
	});

	//methods
	angular.extend($scope, {
		doLogout: function() {
			userModel.doUserLogout();
			//CLEAR THE PARAMS
			$location.search('param', null)
			$location.search('type', null)
      $location.search('path', null)
			
			$location.path('/');
		},
		checkActiveLink: function(routeLink) {
      var routeLinkF = routeLink.replace('#', '');
      if($location.path() == routeLinkF)
      {
        return "active";
      }
		},
    checkActiveLinkAdju: function() {
      if($location.url() == "/orders/new-adjudication")
      {
        return "active";
      }
    },
    checkActiveLinkStock: function() {
      if($location.url() == "/orders/new-adjudication?type=stock")
      {
        return "active";
      }
    },
    checkPerformanceLink: function() {
      if($location.path() == "/performance/problem" || $location.path() == "/performance/highlights" || $location.path() == "/performance/weekly" || $location.path() == "/performance/archive")
      {
        return "active";
      }
    },
    checkSPVLink: function() {
      var location = $location.path();
      var res = location.substring(1, 4);
     if(res == "spv")
     {
      return "active";
     }
    },
		searchAction: function (value, type) {
		user = userModel.getUserObject();
		if(user.state == "preggo" || user.cr == 1)
		{
			$location.path('/orders/search_preggo').search({param: value, type:type});
		}
		else if(user.state == "vendedor")
		{
			$location.path('/orders/search_salesman').search({param: value, type:type});
		}
		else
		{
			$location.path('/orders/search').search({param: value, type:type});
		}	
		},

	});

}]);