myApp.controller('calendarController360', ['$scope', '$location', '$cookies', 'calendarModel', function($scope, $location, $cookies, calendarModel){
	
	var user = $cookies.getObject('auth');
	
	function getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0,0,0);
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    var yearStart = new Date(d.getFullYear(),0,1);
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
    return weekNo;
    }
    //FUNCTION WEEKS BASED ON YEAR
    function weeksInYear(year) {
    var month = 11, day = 31, week;

    do {
        d = new Date(year, month, day--);
        week = getWeekNumber(d);
    } while (week == 1);

    return week;
    }

    /* GET QUERY */
	var year = new Date().getFullYear();
    var today = new Date();
    $scope.actual_year = year;
    var yearStart = 2015;
    $scope.object_years = [];
    $scope.object_weeks = [];

    while(yearStart < year + 1){
        $scope.object_years.push(yearStart++);
    }
    var i = 1;



    $scope.atual_week = getWeekNumber(today);
    var atual_week = getWeekNumber(today);
    $scope.weeks = weeksInYear(year);
    var weeks = weeksInYear(year);
    while(i < weeks + 1) {
        $scope.object_weeks.push(i++)
    }


    if(user.cr == 1)
    {
        $scope.Reading = [
        {value: 'Supplier'},
        {value: 'Wish'},
        ];

    }
    else
    {
        $scope.Reading = [
        {value: 'Supplier'},
        {value: 'Wish'},
        {value: 'Promisse'}
        ];

    }

  	$scope.changeReading = "Supplier";

  	$scope.types = [
  	{value: 'Total'},
  	{value: 'Clientes'},
  	{value: 'Stock'}
  	];

  	$scope.changeType = "Total";


	/*Variables */
	angular.extend($scope, {
	changeYear : "" + year + "",
    changeWeek: "" + atual_week + "",
    fixedYear: year,
	Week: atual_week,
	nextWeek : atual_week + 1,
    nextWeek_2 : atual_week + 2,
    nextWeek_3 : atual_week + 3,
    nextWeek_4 : atual_week + 4,
    nextWeek_5 : atual_week + 5,
    nextWeek_6 : atual_week + 6,
    nextWeek_7 : atual_week + 7,
    nextWeek_8 : atual_week + 8,
    nextWeek_9 : atual_week + 9,
    nextWeek_10 : atual_week + 10,
    nextWeek_11 : atual_week + 11,

    //VARIABLES OF SUMS BY WEEKS
    sumDanger_t1 : 0,
    sumOrange_t1: 0,
    sumYellow_t1: 0,
    sumGreen_t1: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 2
    sumDanger_t2: 0,
    sumOrange_t2: 0,
    sumYellow_t2: 0,
    sumGreen_t2: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 3
    sumDanger_t3: 0,
    sumOrange_t3: 0,
    sumYellow_t3: 0,
    sumGreen_t3: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 4
    sumDanger_t4: 0,
    sumOrange_t4: 0,
    sumYellow_t4: 0,
    sumGreen_t4: 0,

     //VARIABLES OF SUMS BY WEEKS TABLE 5
    sumDanger_t5: 0,
    sumOrange_t5: 0,
    sumYellow_t5: 0,
    sumGreen_t5: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 6
    sumDanger_t6: 0,
    sumOrange_t6: 0,
    sumYellow_t6: 0,
    sumGreen_t6: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 7
    sumDanger_t7: 0,
    sumOrange_t7: 0,
    sumYellow_t7: 0,
    sumGreen_t7: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 8
    sumDanger_t8: 0,
    sumOrange_t8: 0,
    sumYellow_t8: 0,
    sumGreen_t8: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 9
    sumDanger_t9: 0,
    sumOrange_t9: 0,
    sumYellow_t9: 0,
    sumGreen_t9: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 10
    sumDanger_t10: 0,
    sumOrange_t10: 0,
    sumYellow_t10: 0,
    sumGreen_t10: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 11
    sumDanger_t11: 0,
    sumOrange_t11: 0,
    sumYellow_t11: 0,
    sumGreen_t11: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 12
    sumDanger_t12: 0,
    sumOrange_t12: 0,
    sumYellow_t12: 0,
    sumGreen_t12: 0,

    sumNP: 0,


	});



	// BASE FUNCTIONS TO CONSTRUCT THE TABLES
	calendarModel.getAtualWeek_360(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data){
        $scope.Adjudications = data;

        var orderedAdj = _.groupBy($scope.Adjudications, "PO");
        $scope.sum_PO = _.size(orderedAdj);

        if($scope.sum_PO > 0)
        {
        $scope.showTable_1 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
        	switch (true) {
        		case (value.week_c <= -4):
        		$scope.sumGreen_t1 += 1;
        		break;

        		case (value.week_c == -2 || value.week_c == -3):
        		$scope.sumYellow_t1 += 1;
        		break;

        		case (value.week_c == 0 || value.week_c == -1):
        		$scope.sumOrange_t1 += 1;
        		break;

        		case (value.week_c >= 1):
        		$scope.sumDanger_t1 += 1;
        		break;
        	}
        	
  			
		});

    });

	calendarModel.getNextWeek_360(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
		$scope.Adjudications_next_week = data;

		var orderedAdj_week = _.groupBy($scope.Adjudications_next_week, "PO");
		$scope.sum_PO_next_week = _.size(orderedAdj_week);

        if($scope.sum_PO_next_week > 0)
        {
            $scope.showTable_2 = true;
        }

		angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
        	switch (true) {
        		case (value.week_c <= -4):
        		$scope.sumGreen_t2 += 1;
        		break;

        		case (value.week_c == -2 || value.week_c == -3):
        		$scope.sumYellow_t2 += 1;
        		break;

        		case (value.week_c == 0 || value.week_c == -1):
        		$scope.sumOrange_t2 += 1;
        		break;

        		case (value.week_c >= 1):
        		$scope.sumDanger_t2 += 1;
        		break;
        	}
        	
  			
		});

		
	});

    calendarModel.getNextWeek_360_2(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_2 = data;

        var orderedAdj_week_2 = _.groupBy($scope.Adjudications_next_week_2, "PO");
        $scope.sum_PO_next_week_2 = _.size(orderedAdj_week_2);

        if($scope.sum_PO_next_week_2 > 0)
        {
            $scope.showTable_3 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t3 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t3 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t3 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t3 += 1;
                break;
            }
            
            
        });

    });

    calendarModel.getNextWeek_360_3(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_3 = data;

        var orderedAdj_week_3 = _.groupBy($scope.Adjudications_next_week_3, "PO");
        $scope.sum_PO_next_week_3 = _.size(orderedAdj_week_3);

        if($scope.sum_PO_next_week_3 > 0)
        {
            $scope.showTable_4 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t4 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t4 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t4 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t4 += 1;
                break;
            }
            
            
        });

    });

    calendarModel.getNextWeek_360_4(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_4 = data;

        var orderedAdj_week_4 = _.groupBy($scope.Adjudications_next_week_4, "PO");
        $scope.sum_PO_next_week_4 = _.size(orderedAdj_week_4);

         if($scope.sum_PO_next_week_4 > 0)
        {
            $scope.showTable_5 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t5 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t5 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t5 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t5 += 1;
                break;
            }
            
            
        });

    });

    calendarModel.getNextWeek_360_5(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_5 = data;

        var orderedAdj_week_5 = _.groupBy($scope.Adjudications_next_week_5, "PO");
        $scope.sum_PO_next_week_5 = _.size(orderedAdj_week_5);

         if($scope.sum_PO_next_week_5 > 0)
        {
            $scope.showTable_6 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t6 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t6 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t6 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t6 += 1;
                break;
            }
            
            
        });

    });

    calendarModel.getNextWeek_360_6(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_6 = data;

        var orderedAdj_week_6 = _.groupBy($scope.Adjudications_next_week_6, "PO");
        $scope.sum_PO_next_week_6 = _.size(orderedAdj_week_6);

        if($scope.sum_PO_next_week_6 > 0)
        {
            $scope.showTable_7 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t7 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t7 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t7 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t7 += 1;
                break;
            }
            
            
        });

    });
    calendarModel.getNextWeek_360_7(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_7 = data;

        var orderedAdj_week_7 = _.groupBy($scope.Adjudications_next_week_7, "PO");
        $scope.sum_PO_next_week_7 = _.size(orderedAdj_week_7);

        if($scope.sum_PO_next_week_7 > 0)
        {
            $scope.showTable_8 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t8 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t8 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t8 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t8 += 1;
                break;
            }
            
            
        });

    });
    calendarModel.getNextWeek_360_8(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_8 = data;

        var orderedAdj_week_8 = _.groupBy($scope.Adjudications_next_week_8, "PO");
        $scope.sum_PO_next_week_8 = _.size(orderedAdj_week_8);

        if($scope.sum_PO_next_week_8 > 0)
        {
            $scope.showTable_9 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t9 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t9 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t9 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t9 += 1;
                break;
            }
            
            
        });

    });
    calendarModel.getNextWeek_360_9(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_9 = data;

        var orderedAdj_week_9 = _.groupBy($scope.Adjudications_next_week_9, "PO");
        $scope.sum_PO_next_week_9 = _.size(orderedAdj_week_9);

        if($scope.sum_PO_next_week_9 > 0)
        {
            $scope.showTable_10 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t10 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t10 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t10 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t10 += 1;
                break;
            }
            
            
        });

    });
    calendarModel.getNextWeek_360_10(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_10 = data;

        var orderedAdj_week_10 = _.groupBy($scope.Adjudications_next_week_10, "PO");
        $scope.sum_PO_next_week_10 = _.size(orderedAdj_week_10);

        if($scope.sum_PO_next_week_10 > 0)
        {
            $scope.showTable_11 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t11 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t11 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t11 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t11 += 1;
                break;
            }
            
            
        });

    });

    calendarModel.getNextWeek_360_11(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
        $scope.Adjudications_next_week_11 = data;

        var orderedAdj_week_11 = _.groupBy($scope.Adjudications_next_week_11, "PO");
        $scope.sum_PO_next_week_11 = _.size(orderedAdj_week_11);

        if($scope.sum_PO_next_week_11 > 0)
        {
            $scope.showTable_12 = true;
        }

        angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t12 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t12 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t12 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t12 += 1;
                break;
            }
            
            
        });

    });

	function refreshData(changeWeek, changeYear, type_reading, type_po) {

        $scope.sumNP = 0;

      calendarModel.getAtualWeek_360(changeWeek, changeYear, type_reading, type_po).success(function(data){
        $scope.Adjudications = data;

        var orderedAdj = _.groupBy($scope.Adjudications, "PO");
        $scope.sum_PO = _.size(orderedAdj);


        if($scope.sum_PO <= 0)
        {
            $scope.showTable_1 = false;
        }
        else
        {
             $scope.showTable_1 = true;
        }

        $scope.sumGreen_t1 = 0;
        $scope.sumYellow_t1 = 0;
        $scope.sumOrange_t1 = 0;
        $scope.sumDanger_t1 = 0;

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t1 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t1 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t1 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t1 += 1;
                break;
            }
            
            });

        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t1 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t1 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t1 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t1 += 1;
                break;
            }
            
            });
        }


    });

	calendarModel.getNextWeek_360(changeWeek, changeYear, type_reading, type_po).success(function(data) {
		$scope.Adjudications_next_week = data;

		var orderedAdj_week = _.groupBy($scope.Adjudications_next_week, "PO");
		$scope.sum_PO_next_week = _.size(orderedAdj_week);

		$scope.sumGreen_t2 = 0;
        $scope.sumYellow_t2 = 0;
        $scope.sumOrange_t2 = 0;
        $scope.sumDanger_t2 = 0;

        if($scope.sum_PO_next_week <= 0)
        {
            $scope.showTable_2 = false;
        }
        else
        {
            $scope.showTable_2 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t2 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t2 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t2 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t2 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t2 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t2 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t2 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t2 += 1;
                break;
            }
            
            });
        }

	});

    calendarModel.getNextWeek_360_2(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_2 = data;

        var orderedAdj_week_2 = _.groupBy($scope.Adjudications_next_week_2, "PO");
        $scope.sum_PO_next_week_2 = _.size(orderedAdj_week_2);

        $scope.sumGreen_t3 = 0;
        $scope.sumYellow_t3 = 0;
        $scope.sumOrange_t3 = 0;
        $scope.sumDanger_t3 = 0;

        if($scope.sum_PO_next_week_2 <= 0)
        {
            $scope.showTable_3 = false;
        }
        else
        {
            $scope.showTable_3 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t3 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t3 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t3 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t3 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t3 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t3 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t3 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t3 += 1;
                break;
            }
            
            });
        }

    });

    calendarModel.getNextWeek_360_3(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_3 = data;

        var orderedAdj_week_3 = _.groupBy($scope.Adjudications_next_week_3, "PO");
        $scope.sum_PO_next_week_3 = _.size(orderedAdj_week_3);

        $scope.sumGreen_t4 = 0;
        $scope.sumYellow_t4 = 0;
        $scope.sumOrange_t4 = 0;
        $scope.sumDanger_t4 = 0;

        if($scope.sum_PO_next_week_3 <= 0)
        {
            $scope.showTable_4 = false;
        }
        else
        {
            $scope.showTable_4 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t4 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t4 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t4 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t4 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t4 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t4 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t4 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t4 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_4(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_4 = data;

        var orderedAdj_week_4 = _.groupBy($scope.Adjudications_next_week_4, "PO");
        $scope.sum_PO_next_week_4 = _.size(orderedAdj_week_4);

        $scope.sumGreen_t5 = 0;
        $scope.sumYellow_t5 = 0;
        $scope.sumOrange_t5 = 0;
        $scope.sumDanger_t5 = 0;

        if($scope.sum_PO_next_week_4 <= 0)
        {
            $scope.showTable_5 = false;
        }
        else
        {
            $scope.showTable_5 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t5 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t5 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t5 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t5 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t5 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t5 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t5 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t5 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_5(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_5 = data;

        var orderedAdj_week_5 = _.groupBy($scope.Adjudications_next_week_5, "PO");
        $scope.sum_PO_next_week_5 = _.size(orderedAdj_week_5);

        $scope.sumGreen_t6 = 0;
        $scope.sumYellow_t6 = 0;
        $scope.sumOrange_t6 = 0;
        $scope.sumDanger_t6 = 0;

        if($scope.sum_PO_next_week_5 <= 0)
        {
            $scope.showTable_6 = false;
        }
        else
        {
            $scope.showTable_6 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t6 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t6 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t6 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t6 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t6 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t6 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t6 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t6 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_6(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_6 = data;

        var orderedAdj_week_6 = _.groupBy($scope.Adjudications_next_week_6, "PO");
        $scope.sum_PO_next_week_6 = _.size(orderedAdj_week_6);

        $scope.sumGreen_t7 = 0;
        $scope.sumYellow_t7 = 0;
        $scope.sumOrange_t7 = 0;
        $scope.sumDanger_t7 = 0;

        if($scope.sum_PO_next_week_6 <= 0)
        {
            $scope.showTable_7 = false;
        }
        else
        {
            $scope.showTable_7 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t7 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t7 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t7 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t7 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t7 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t7 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t7 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t7 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_7(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_7 = data;

        var orderedAdj_week_7 = _.groupBy($scope.Adjudications_next_week_7, "PO");
        $scope.sum_PO_next_week_7 = _.size(orderedAdj_week_7);

        $scope.sumGreen_t8 = 0;
        $scope.sumYellow_t8 = 0;
        $scope.sumOrange_t8 = 0;
        $scope.sumDanger_t8 = 0;

        if($scope.sum_PO_next_week_7 <= 0)
        {
            $scope.showTable_8 = false;
        }
        else
        {
            $scope.showTable_8 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t8 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t8 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t8 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t8 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t8 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t8 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t8 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t8 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_8(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_8 = data;

        var orderedAdj_week_8 = _.groupBy($scope.Adjudications_next_week_8, "PO");
        $scope.sum_PO_next_week_8 = _.size(orderedAdj_week_8);

        $scope.sumGreen_t9 = 0;
        $scope.sumYellow_t9 = 0;
        $scope.sumOrange_t9 = 0;
        $scope.sumDanger_t9 = 0;

        if($scope.sum_PO_next_week_8 <= 0)
        {
            $scope.showTable_9 = false;
        }
        else
        {
            $scope.showTable_9 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t9 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t9 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t9 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t9 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t9 += 1;
                break;

                case (value.week_c == 5 ):
                $scope.sumYellow_t9 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t9 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t9 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_9(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_9 = data;

        var orderedAdj_week_9 = _.groupBy($scope.Adjudications_next_week_9, "PO");
        $scope.sum_PO_next_week_9 = _.size(orderedAdj_week_9);

        $scope.sumGreen_t10 = 0;
        $scope.sumYellow_t10 = 0;
        $scope.sumOrange_t10 = 0;
        $scope.sumDanger_t10 = 0;

        if($scope.sum_PO_next_week_9 <= 0)
        {
            $scope.showTable_10 = false;
        }
        else
        {
            $scope.showTable_10 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t10 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t10 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t10 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t10 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t10 += 1;
                break;

                case (value.week_c == 5):
                $scope.sumYellow_t10 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t10 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t10 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_10(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_10 = data;

        var orderedAdj_week_10 = _.groupBy($scope.Adjudications_next_week_10, "PO");
        $scope.sum_PO_next_week_10 = _.size(orderedAdj_week_10);

        $scope.sumGreen_t11 = 0;
        $scope.sumYellow_t11 = 0;
        $scope.sumOrange_t11 = 0;
        $scope.sumDanger_t11 = 0;

        if($scope.sum_PO_next_week_10 <= 0)
        {
            $scope.showTable_11 = false;
        }
        else
        {
            $scope.showTable_11 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t11 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t11 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t11 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t11 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t11 += 1;
                break;

                case (value.week_c == 5 ):
                $scope.sumYellow_t11 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t11 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t11 += 1;
                break;
            }
            
            });
        }

    });
    calendarModel.getNextWeek_360_11(changeWeek, changeYear, type_reading, type_po).success(function(data) {
        $scope.Adjudications_next_week_11 = data;

        var orderedAdj_week_11 = _.groupBy($scope.Adjudications_next_week_11, "PO");
        $scope.sum_PO_next_week_11 = _.size(orderedAdj_week_11);

        $scope.sumGreen_t12 = 0;
        $scope.sumYellow_t12 = 0;
        $scope.sumOrange_t12 = 0;
        $scope.sumDanger_t12 = 0;

        if($scope.sum_PO_next_week_11 <= 0)
        {
            $scope.showTable_12 = false;
        }
        else
        {
            $scope.showTable_12 = true;
        }

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t12 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t12 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t12 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t12 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            $scope.sumNP += value.qtnd;
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t12 += 1;
                break;

                case (value.week_c == 5 ):
                $scope.sumYellow_t12 += 1;
                break;

                case (value.week_c == 6):
                $scope.sumOrange_t12 += 1;
                break;

                case (value.week_c >= 7):
                $scope.sumDanger_t12 += 1;
                break;
            }
            
            });
        }

    });
    }



	angular.extend($scope, {
		defineClass: function(week, type) {
			if(type == "Supplier" || type == "Promisse")
			{
				switch (true) {
					case (week <= -4):
					return "positive_apply_calendar_absolute";
					break;

					case (week == -2 || week == -3):
					return "yellow_apply_calendar_absolute";
					break;

					case (week == 0 || week == -1):
					return "orange_apply_calendar_absolute";
					break;

					case (week >= 1):
					return "danger_apply_calendar_absolute";
					break;
				}
			}
			else
			{
				switch (true) {
					case (week >= 7):
					return "danger_apply_calendar_absolute";
					break;

					case (week == 6):
					return "orange_apply_calendar_absolute";
					break;

					case (week == 5):
					return "yellow_apply_calendar_absolute";
					break;

					case (week <= 4):
					return "positive_apply_calendar_absolute";
					break;
				}

			}

		},
		changeData: function(week, year, type_reading, type_po) {
            
            refreshData(week, year, type_reading, type_po);

            //reload variables of weeks
            $scope.nextWeek = parseInt(week) + 1;
            $scope.nextWeek_2 = parseInt(week) + 2;
            $scope.nextWeek_3 = parseInt(week) + 3;
            $scope.nextWeek_4 = parseInt(week) + 4;
            $scope.nextWeek_5 = parseInt(week) + 5;
            $scope.nextWeek_6 = parseInt(week) + 6;
            $scope.nextWeek_7 = parseInt(week) + 7;
            $scope.nextWeek_8 = parseInt(week) + 8;
            $scope.nextWeek_9 = parseInt(week) + 9;
            $scope.nextWeek_10 = parseInt(week) + 10;
            $scope.nextWeek_11 = parseInt(week) + 11;

            if(type_reading == 'Promisse') 
            {
                $scope.changeType = "Clientes";

            }
		},

        sumTotalDanger: function() {
            return $scope.sumDanger_t1 + $scope.sumDanger_t2 + $scope.sumDanger_t3 + $scope.sumDanger_t4 + $scope.sumDanger_t5 + $scope.sumDanger_t6 + $scope.sumDanger_t7 + $scope.sumDanger_t8 + $scope.sumDanger_t9 + $scope.sumDanger_t10 + $scope.sumDanger_t11 + $scope.sumDanger_t12;
        },
        sumTotalOrange: function() {
            return $scope.sumOrange_t1 + $scope.sumOrange_t2 + $scope.sumOrange_t3 + $scope.sumOrange_t4 + $scope.sumOrange_t5 + $scope.sumOrange_t6 + $scope.sumOrange_t7 + $scope.sumOrange_t8 + $scope.sumOrange_t9 + $scope.sumOrange_t10 + $scope.sumOrange_t11 + $scope.sumOrange_t12;
        },
        sumTotalYellow: function() {
            return $scope.sumYellow_t1 + $scope.sumYellow_t2 + $scope.sumYellow_t3 + $scope.sumYellow_t4 + $scope.sumYellow_t5 + $scope.sumYellow_t6 + $scope.sumYellow_t7 + $scope.sumYellow_t8 + $scope.sumYellow_t9 + $scope.sumYellow_t10 + $scope.sumYellow_t11 + $scope.sumYellow_t12;
        },
        sumTotalGreen: function() {
            return $scope.sumGreen_t1 + $scope.sumGreen_t2 + $scope.sumGreen_t3 + $scope.sumGreen_t4 + $scope.sumGreen_t5 + $scope.sumGreen_t6 + $scope.sumGreen_t7 + $scope.sumGreen_t8 + $scope.sumGreen_t9 + $scope.sumGreen_t10 + $scope.sumGreen_t11 + $scope.sumGreen_t12;
        },
        sumTotalPO: function() {
            return $scope.sum_PO + $scope.sum_PO_next_week + $scope.sum_PO_next_week_2 + $scope.sum_PO_next_week_3 + $scope.sum_PO_next_week_4 + $scope.sum_PO_next_week_5 + $scope.sum_PO_next_week_6 + $scope.sum_PO_next_week_7 + $scope.sum_PO_next_week_8 + $scope.sum_PO_next_week_9 + $scope.sum_PO_next_week_10 + $scope.sum_PO_next_week_11;
        },
        basicSum: function(value) {
            return value;
        },

		PrintTable: function() {


        var week = $("#changeWeek").val();
        var year = $("#changeYear").val();
        var read = $("#changeReading").val();
        var type = $("#changeType").val();

        newWin= window.open("");
        newWin.document.write('<html>');
        newWin.document.write('<head><link rel="stylesheet" type="text/css" href="' + baseUrl + 'css/print-calendar.css"></head>');
        newWin.document.write('<body>');
        newWin.document.write('<div style="text-align:center"><img src="' + baseUrl +'images/logo.png" width="200" height="30" /></div>');
        newWin.document.write('<div class="title-page">Calendário de Produção por Semana <b> ' + year + '</b> previsto em <b>' + read + '</b> do tipo <b>' + type + '</b> próximas 12 semanas.</div>');
        for (i = 1; i <= 12; i++) {
            var int_1 = i - 1;
            var string_week = parseInt(week) + int_1;
            
            var string = "showTable_" + i;
            if($scope[string]  == true)
            {
                 newWin.document.write('<div class="title-week">Semana <b>' + string_week + '</b></div>');
                 newWin.document.write(document.getElementById("table_" + i).outerHTML);
            }
        }
        newWin.document.write('</body></html>');

        //GET THE BROWSER 
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        //IS SAFARI
        if (isSafari == true) {
            $(newWin).ready(function() {  
         setTimeout(
            function(){
            newWin.print();
            newWin.close();
            },(300));
        });
        }
        //IS FIREFOX
        else if(isFirefox == true){
         newWin.print();
         newWin.close();
        }
        //IS IE
        else if(isIE == true) {
            newWin.print();
        }
        //IS CHROME
        else if(isChrome == true){
            $(newWin).ready(function() {  
                setTimeout(
                    function(){
                    newWin.print();
                    newWin.close();
                },(600));
            });
        }

        }
	
	
	});

}]);