myApp.controller('providersController', ['$scope', 'providerModel', '$routeParams', 
	function ($scope, providerModel, $routeParams) {
	
	providerModel.getProviders().success(function(response){
		$scope.providers = response;
		$scope.showProviders = true;
	});

	/*Variables */
	angular.extend($scope, {
		newProvider: {}
	
	});

	/* Functions */
	angular.extend($scope, {
		//SAVE PROVIDER
		saveProvider: function (addProvider) {
			if(addProvider.$valid) {
				$scope.formSubmitted = false;
				providerModel.saveProvider($scope.newProvider).success(function(response){
					//REFRESH DATA
					$scope.providers = response;
					$scope.newProvider={};
				});
			} else {
				$scope.formSubmitted = true;
				console.log('Error');
			}
		},
		deleteProvider: function(id) {
			 if (confirm("Are you sure you want to delete?")) {
        		providerModel.deleteProvider(id).success(function(response){
				$scope.providers = response;	
			});
    		}
		},
	




	});

}]);