myApp.controller('costumerRelationController', ['$scope', 'costumerModel', '$routeParams', 
	function ($scope, costumerModel, $routeParams) {
	
	costumerModel.getCostumers().success(function(response){
		$scope.costumers = response;
		$scope.showCostumers = true;
	});

	//TIPOS DE ESTADOS
  	$scope.estados = [
  	{value: 'admin', text: 'Admin'},
  	{value: 'admin vendedor', text: 'Admin Vendedor'},
  	{value: 'preggo', text: 'Preggo Gestor'},
  	{value: 'vendedor', text: 'Vendedor'},
  	];

	/*Variables */
	angular.extend($scope, {
		newCostumer: {}
	
	});

	function refreshData() {
		costumerModel.getCostumers().success(function(response){
		$scope.costumers = response;
		});
	}



	/* Functions */
	angular.extend($scope, {
		//SAVE COSTUMER
		saveCostumer: function (addCostumer) {
			if(addCostumer.$valid) {
				$scope.formSubmitted = false;
				costumerModel.saveCostumer($scope.newCostumer).success(function(response){
					//REFRESH DATA
					refreshData();
					$scope.newCostumer={};
				});
			} else {
				$scope.formSubmitted = true;
				console.log('Error');
			}
		},
		deleteCostumer: function(id) {
			 if (confirm("Are you sure you want to delete?")) {
        		costumerModel.deleteCostumer(id).success(function(response){
				refreshData();
			});
    		}
		},
		changeCR: function (state, id) {
			if(state == true)
			{
				costumerModel.StateChecked(id).success(function(){
					refreshData();
				});
			}
			else
			{
				costumerModel.StateNOTChecked(id).success(function(){
					refreshData();
				});
			}
		},
		editRow: function(value, type, id) {
			costumerModel.updateRow(value, type, id).success(function(response) {
				refreshData();
			})
		},





	});

}]);