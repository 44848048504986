myApp.controller('PerformanceController', ['$scope', '$location', '$cookies', '$timeout', 'dashboardModel', '$routeParams',
	function ($scope, $location, $cookies, $timeout, dashboardModel,  $routeParams) {
	
    var user = $cookies.getObject('auth');

    $scope.user_state = user.state;
    $scope.cr = user.cr;

    ///----------------------------- BASE FUNCTIONS ---------------------------////
	function getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0,0,0);
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    var yearStart = new Date(d.getFullYear(),0,1);
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
    return weekNo;
    }
    //FUNCTION WEEKS BASED ON YEAR
    function weeksInYear(year) {
    var month = 11, day = 31, week;

    do {
        d = new Date(year, month, day--);
        week = getWeekNumber(d);
    } while (week == 1);

    return week;
    }

    /* GET QUERY */
	var year = new Date().getFullYear();
    var today = new Date();
    $scope.actual_year = year;
    var yearStart = 2015;
    $scope.object_years = [];
    $scope.object_weeks = [];

    while(yearStart < year + 1){
        $scope.object_years.push(yearStart++);
    }
    var i = 1;



    $scope.atual_week = getWeekNumber(today);
    var atual_week = getWeekNumber(today);
    $scope.weeks = weeksInYear(year);
    var weeks = weeksInYear(year);
    while(i < weeks + 1) {
        $scope.object_weeks.push(i++)
    }

    if(user.cr == 1)
    {
        $scope.types = [
        {value: 'Contrato Fornecimento'},
        {value: 'Wish'}
        ];

        $scope.changeType = "Contrato Fornecimento";
    }
    else
    {
        $scope.types = [
        {value: 'Terms and Conditions'},
        {value: 'Contrato Fornecimento'},
        {value: 'Wish'}
        ];

        $scope.changeType = "Terms and Conditions";
    }
    

    ///----------------------------- BASE FUNCTIONS ---------------------------////


    if(user.state == "vendedor")
    {
        dashboardModel.getDimensionProblem_sales(atual_week, year, $scope.changeType, user.sigla).success(function(data) {
            $scope.FocoHighlights = data;
        });
    }
    else
    {
        dashboardModel.getDimensionProblem(atual_week, year, $scope.changeType).success(function(data) {
            $scope.FocoHighlights = data;
        });
    }
     


     function refreshData(changeWeek, changeYear, type) {

    if(user.state == "vendedor")
    {
        dashboardModel.getDimensionProblem_sales(changeWeek, changeYear, type, user.sigla).success(function(data) {
            $scope.FocoHighlights = data;
        }); 
    }
    else
    {
        dashboardModel.getDimensionProblem(changeWeek, changeYear, type).success(function(data) {
            $scope.FocoHighlights = data;
        }); 
    }

    }

   


    //FUNCTIONS TO RELOAD THE PAGE
   


	/*Variables */
	angular.extend($scope, {
    changeYear : "" + year + "",
    changeWeek: "" + atual_week + "",
    fixedYear: year,
    nextWeek : atual_week + 1,
    nextWeek_2 : atual_week + 2,
    nextWeek_3 : atual_week + 3,
	});

	/* Functions */
	angular.extend($scope, {
		
        getNextWeek: function(value, value2) {
            return parseInt(value) + parseInt(value2);
        },
        basicSum: function(value1, value2) {
            var valor_parcial = value1 + value2;
            var valor_final = Math.round(valor_parcial * 100) / 100;
            return valor_final.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

        },
        checkType:function(value) {
            if(value == "Contrato Fornecimento")
            {
                return "highlight_sub_blue";
            }
            else if(value == "Wish") {
                return "highlight_sub_green";
            }
        },
        checkTypeDelay: function(value) {
            if(value == "Contrato Fornecimento" || value == "Wish") {
                return "highlight_blue";
            }
        },
        changeData: function(week, year, type) {
            refreshData(week, year, type);

            //reload variables of weeks
            $scope.nextWeek = parseInt(week) + 1;
            $scope.nextWeek_2 = parseInt(week) + 2;
            $scope.nextWeek_3 = parseInt(week) + 3;
        },

        DownloadPDF: function() {
        var divToPrint=document.getElementById("status-text");
        var week = $("#changeWeek").val();
        var year = $("#changeYear").val();
        newWin= window.open("");
        newWin.document.write('<html>');
        newWin.document.write('<head><link rel="stylesheet" type="text/css" href="' + baseUrl + 'css/print-status-text.css"></head>');
        newWin.document.write('<body>');
        newWin.document.write('<div style="text-align:center"><img src="' + baseUrl +'images/logo.png" width="200" height="30" /></div>');
        newWin.document.write('<div class="title-page">Status Produção Boca do Lobo Semana <b>' + week + '</b> de <b> ' + year + '</b></div>');
        newWin.document.write(divToPrint.outerHTML);
        newWin.document.write('</body></html>');

        //GET THE BROWSER 
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isSafari == true) {
            $(newWin).ready(function() {  
         setTimeout(
            function(){
            newWin.print();
            newWin.close();
            },(300));
        });
        }
        else if(isFirefox == true){
         newWin.print();
         newWin.close();
        }
        else if(isIE == true) {
            newWin.print();
        }
        else if(isChrome == true){
            $(newWin).ready(function() {  
                setTimeout(
                    function(){
                    newWin.print();
                    newWin.close();
                },(600));
            });
        }

        }



	});

}]);