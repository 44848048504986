myApp.controller('modalController', ['$scope', 'orderModel', 'productModel', '$uibModalInstance', 'item', 
	function($scope, orderModel, productModel, $uibModalInstance, item){
	
$scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {
		ProductNow: {},

	});

  //GET PRODUCT
  orderModel.getDimensions(item).success(function(response){
	$scope.product_ = response;
	});

//functions
angular.extend($scope, {
  UpdateProduct: function (editProduct) {
  	if(editProduct.$valid) {
		$scope.formSubmitted = false;
		productModel.editProduct($scope.product_[0], item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });
}]);

myApp.controller('modalControllerAdd', ['$scope', 'productModel', '$uibModalInstance',
	function($scope, productModel, $uibModalInstance){
	


  /*Variables */
	angular.extend($scope, {
		addProduct: {},

	});


//functions
angular.extend($scope, {
  AddProduct: function (newProduct) {
  	if(newProduct.$valid) {
		$scope.formSubmitted = false;
		productModel.addProduct($scope.addProduct).success(function(response){
			$uibModalInstance.close();
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });



}]);

myApp.controller('modalControllerResponsavel', ['$scope', 'orderModel', 'responsavelModel', '$uibModalInstance', 'item',
	function($scope, orderModel, responsavelModel, $uibModalInstance, item){
	


  $scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {
		orderRP: {},

	});

//GET PRODUCT
 orderModel.getCustomDimension(item).success(function(response){
	$scope.order = response;
});
 //GET RESPONSAVEIS
 responsavelModel.getResponsavel().success(function(response){
	$scope.responsaveis = response;
});



//functions
angular.extend($scope, {
  UpdateResponsavel: function (editResponsavel) {
  	if(editResponsavel.$valid) {
		$scope.formSubmitted = false;
		orderModel.editResponsavelPreggo($scope.order[0], item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });



}]);

myApp.controller('modalControllerStatus', ['$scope', 'orderModel', 'userModel', '$uibModalInstance', 'item', 'PO',
	function($scope, orderModel, userModel, $uibModalInstance, item, PO){
	


  $scope.item = item;
  $scope.PO = PO;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {
		user: userModel.getUserObject(),
		order: {},

	});




//functions
angular.extend($scope, {
  UpdateStatus: function (editStatus) {
  	if(editStatus.$valid) {
		$scope.formSubmitted = false;
		orderModel.editStatus($scope.order, $scope.user, item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  	}
 });

}]);


myApp.controller('modalControllerFornecedores', ['$scope', 'orderModel', 'providerModel', '$uibModalInstance', 'item',
	function($scope, orderModel, providerModel, $uibModalInstance, item){
	


  $scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {
		orderRP: {},

	});

//GET PRODUCT
 orderModel.getFornecedores(item).success(function(response){
	var names = [];

    for(var i = 0; i < response.length; i++) {
     var obj = response[i];

        if(obj.nome != undefined) {
            names.push(obj.nome);
        }

    }
    $scope.forns = names;
});

//GET RESPONSAVEIS
 providerModel.getProviders().success(function(response){
	$scope.providers = response;
});


//functions
angular.extend($scope, {
  UpdateProviders: function (editFornecedores) {
  	if(editFornecedores.$valid) {
		$scope.formSubmitted = false;
		orderModel.editFornecedores($scope.forns, item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });



}]);

myApp.controller('modalControllerEditProduct', ['$scope', 'orderModel', '$uibModalInstance', 'item',
	function($scope, orderModel, $uibModalInstance, item){
	
  $scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

/*Variables */
angular.extend($scope, {
	Produto_add: {},
	p_diameter: null,
	p_width: null,
	p_height: null,
	p_depth: null,
	DefaultDim : true,

});

orderModel.getProducts().success(function(response){
		$scope.products = response;
		$scope.showProducts = true;

});

orderModel.getUniqueNP(item).success(function(response) {
	$scope.PO = response;
});


//functions
angular.extend($scope, {
  UpdateProduto: function (editProduto) {
  	if(editProduto.$valid) {
		$scope.formSubmitted = false;
		orderModel.editProdutoOrder($scope.Produto_add, $scope.p_diameter, $scope.p_width, $scope.p_height, $scope.p_depth, item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  },
  //GET DIMENSIONS
DimensionsTake: function (produto) {
	orderModel.getDimensions(produto).success(function(response){
		$scope.showDimension = true;
		$scope.p_diameter = response[0].diameter;
		$scope.p_width = response[0].width;
		$scope.p_height = response[0].height;
		$scope.p_depth= response[0].depth;
				
	});
},
ChangeCustom: function(type) {
			if(type == "Custom")
			{
				$scope.DefaultDim = false;
			}
			else
			{
				$scope.DefaultDim = true;
			}
		},
  });



}]);

myApp.controller('modalControllerAnotation', ['$scope', 'orderModel', 'userModel', '$uibModalInstance', 'item',
	function($scope, orderModel, userModel, $uibModalInstance, item){
	


  $scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {
		user: userModel.getUserObject(),
		Anotation: {},

	});




//functions
angular.extend($scope, {
  UpdateAnotation: function (NewAnotation) {
  	if(NewAnotation.$valid) {
		$scope.formSubmitted = false;
		orderModel.NewAnotation($scope.Anotation, $scope.user, item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });



}]);



myApp.controller('modalControllerAddImparidade', ['$scope', 'imparidadeModel', '$uibModalInstance',
	function($scope, imparidadeModel, $uibModalInstance){
	


  /*Variables */
	angular.extend($scope, {
		addImparidade: {},

	});


//functions
angular.extend($scope, {
  AddImparidade: function (newImparidade) {
  	if(newImparidade.$valid) {
		$scope.formSubmitted = false;
		imparidadeModel.AddImparidade($scope.addImparidade).success(function(response){
			$uibModalInstance.close();
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
		}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });



}]);

myApp.controller('modalControllerEditImparidade', ['$scope', 'imparidadeModel', '$uibModalInstance', 'item', 
	function($scope,  imparidadeModel, $uibModalInstance, item){
	
$scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {

	});

  //GET PRODUCT
  imparidadeModel.getUniqueImparidade(item).success(function(response){
	$scope.editImparidade = response;
	});

//functions
angular.extend($scope, {
  UpdateImparidade: function (editImparidade) {
  	if(editImparidade.$valid) {
		$scope.formSubmitted = false;
		imparidadeModel.editImparidade($scope.editImparidade, item).success(function(response){
			$uibModalInstance.close($scope.selected.item);
		});
		} else {
			$scope.formSubmitted = true;
			console.log('Error');
	}
  },
  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });
}]);

myApp.controller('modalControllerViewFiles', ['$scope', 'spvModel', '$uibModalInstance', 'item', 
	function($scope, spvModel, $uibModalInstance, item){
	
$scope.item = item;
  $scope.selected = {
    p_id: $scope.item
  };

  /*Variables */
	angular.extend($scope, {

	});

  //GET FILES
 spvModel.getFiles(item).success(function(response){
	$scope.Files = response;
});

//functions
angular.extend($scope, {

  cancel: function () {
    $uibModalInstance.dismiss('cancel');
  }

  });
}]);