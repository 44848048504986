myApp.controller('archiveController', ['$scope', '$location', '$cookies', 'orderModel', 'costumerModel', 'providerModel', '$uibModal', '$timeout', '$routeParams', '$anchorScroll', 
	function ($scope, $location, $cookies, orderModel, costumerModel, providerModel, $uibModal, $timeout, $routeParams, $anchorScroll) {
	
	var user = $cookies.getObject('auth');

	//check if user is admin vendedor to disable the editable 
	if(user.state == "admin vendedor")
	{
		$scope.admin_vendedor = true;
	}
	else
	{
		$scope.admin_vendedor = false;
	}

	function getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0,0,0);
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    var yearStart = new Date(d.getFullYear(),0,1);
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
    return weekNo;
    }

	function weeksInYear(year) {
    var month = 11, day = 31, week;

    do {
        d = new Date(year, month, day--);
        week = getWeekNumber(d);
    } while (week == 1);

    return week;
    }

	/* GET Adjudications */

	//FUNCTION TO GET THE ADJUDICATIONS BASED ON TYPE OF USER
	if($routeParams.path) {
		$scope.Path = $routeParams.path;

		switch($routeParams.path) {

   		case "archive":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});
			}

		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Arquivo Encomendas";
		//ICON
		$scope.icon = "arquivo.png";


        break;

    	case "armazem":
    		if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Clientes em Armazém";
		//ICON
		$scope.icon = "armazem.png";

        break;

        case "stock":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Controlo de Stock";
		//ICON
		$scope.icon = "stock.png";
        break;

        case "expedido":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Expedido";
		//ICON
		$scope.icon = "expedido.png";
        break;

        case "repair":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Arquivo Reparações";
		//ICON
		$scope.icon = "repair.png";
        break;

        case "cemetery":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});

			}
			//GET HIGHTLIGHTS OF CEMETERY
			orderModel.getArchiveCemetery().success(function(response) {
			$scope.CemeteryHighlights = response;
			});
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Cemitério";
		//ICON
		$scope.icon = "cemiterio.png";
        break;

        case "pendentes":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});

			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Pendentes";
		//ICON
		$scope.icon = "time.png";
        break;

        case "recycle":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			$scope.Adjudications = response;
			$scope.showAdjudications = true;

			});

			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Reciclagem";
		//ICON
		$scope.icon = "recycle.png";
        break;

		}


	}

	
	



	/*default select to spec*/
	 $scope.specs = [
    {value: 'Cliente', text: 'Cliente'},
    {value: 'Stock', text: 'Stock'},
    {value: 'Cliente Valditaro', text: 'Cliente Valditaro'},
    {value: 'Stock Valditaro', text: 'Stock Valditaro'}
  	];

  	$scope.tipologias = [
    {value: 'STD ✓', text: 'STD ✓'},
    {value: 'Cst', text: 'Cst'},
    {value: 'RCM ☠', text: 'RCM ☠'},
    {value: 'C FLZ :)', text: 'C FLZ :)'},
	{value: 'SMP', text: 'SMP'},
	{value: 'Prt ♥ Prototipo', text: 'Prt ♥ Prototipo'},
	{value: 'Outros', text: 'Outros'},
  	]; 
  	//TIPOS DE PAGAMENTOS
  	$scope.pagamentos = [
  	{value: 'Pronto-Pagamento', text: 'Pronto-Pagamento'},
  	{value: '30 Dias', text: '30 Dias'},
  	{value: '60 Dias', text: '60 Dias'},
  	{value: '90 Dias', text: '90 Dias'},
  	];

  	$scope.per_page = [
   	{value: 5, text:5},
  	{value: 10, text: 10},
  	{value: 20, text: 20},
  	{value: 30, text: 30},
	];
	$scope.select_page = "5";


	/*Variables */
	angular.extend($scope, {
		warning_dif: false,
		adjudication: {},
		response_: {},
		today: new Date(),
		crs: [],
		providers: [],
		BaseUrl: baseUrl,
		user_id: user.id,

	});

	/* FUNCTIONS PDF MAKE */
	function ReloadData() {
	switch($routeParams.path) {

   		case "archive":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}

		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Arquivo Encomendas";
		//ICON
		$scope.icon = "arquivo.png";


        break;

    	case "armazem":
    		if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Clientes em Armazém";
		//ICON
		$scope.icon = "armazem.png";

        break;

        case "stock":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Controlo de Stock";
		//ICON
		$scope.icon = "stock.png";
        break;

        case "expedido":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Expedido";
		//ICON
		$scope.icon = "expedido.png";
        break;

        case "repair":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Arquivo Reparações";
		//ICON
		$scope.icon = "repair.png";
        break;

        case "cemetery":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}

			//GET HIGHTLIGHTS OF CEMETERY
			orderModel.getArchiveCemetery().success(function(response) {
			$scope.CemeteryHighlights = response;
			});

		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Cemitério";
		//ICON
		$scope.icon = "cemiterio.png";
        break;

        case "pendentes":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Pendentes";
		//ICON
		$scope.icon = "time.png";
        break;

        case "pendentes":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Pendentes";
		//ICON
		$scope.icon = "time.png";
        break;

        case "recycle":
        	if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders($routeParams.path).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			angular.extend($scope.Adjudications, response_2);
			$scope.showAdjudications = true;

			});
			}
		// GLOBAL DYNAMIC VARIABLES TO LAYOUT OF PAGE
		//TITLE
		$scope.Title_archive = "Reciclagem";
		//ICON
		$scope.icon = "recycle.png";
        break;

		}

	}


	

	/* Functions */
	angular.extend($scope, {

		editRow: function(value, type, id) {
			orderModel.updateRow(value, type, id).success(function(response) {
				ReloadData();
			})
		},
		editRow_Product: function(value, type, id) {
			orderModel.updateRow_Product(value, type, id).success(function(response) {
				ReloadData();
			})
		},
		editEnt_Armazem: function(value, id, row) {
			if(value == "Invalid date")
			{
				orderModel.updateEnt_Armazem(value, id).success(function(response) {
					var index = $scope.Adjudications.indexOf(row);
        			if (index !== -1) {
            		$scope.Adjudications.splice(index, 1);
        			}
				})
			}
			else
			{
				orderModel.updateEnt_Armazem(value, id).success(function(response) {
				ReloadData();
				})
			}
			
		},
		loadCRs: function() {
			return $scope.crs.length ? null : costumerModel.getCostumers().success(function(data) {
      			
      		var Final = [];

    		for(var i = 0; i < data.length; i++) {
        	var obj = data[i];

        	if(obj.sigla != undefined) {
        		var data_i = {"value": obj.sigla, "text": obj.sigla};
            Final.push(data_i);
        	}

    		}

      			$scope.crs = Final;
    		});
		},
		loadResponsaveis: function() {
			return $scope.responsaveis.length ? null : orderModel.getResponsaveis().success(function(data) {
      			
      		var Final = [];

    		for(var i = 0; i < data.length; i++) {
        	var obj = data[i];

        	if(obj.name != undefined) {
        		var data_i = {"value": obj.name, "text": obj.name};
            	Final.push(data_i);
        	}

    		}

      			$scope.responsaveis = Final;
    		});
		},
		loadForns: function() {
			return $scope.providers.length ? null : providerModel.getProviders().success(function(data) {
      			
      		var Final = [];

    		for(var i = 0; i < data.length; i++) {
        	var obj = data[i];

        	if(obj.nome != undefined) {
        		var data_i = {"value": obj.nome, "text": obj.nome};
            	Final.push(data_i);
        	}

    		}

      			$scope.providers = Final;
    		});
		},
		//GET NUMBER OF WEEK BASED ON DATE
		getWeekNumber: function (d) {
    	d = new Date(d);
    	d.setHours(0,0,0);
    	d.setDate(d.getDate()+4-(d.getDay()||7));
    	return Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

		},
		//FUNCTION TO DIF ROW
		calDif: function(ent, adj) {
			//CALCULATE WEEK OF ENTRADA
			date_ent = new Date(ent);
			date_ent.setHours(0,0,0)
			date_ent.setDate(date_ent.getDate()+4-(date_ent.getDay()||7));
			week_ent = Math.ceil((((date_ent-new Date(date_ent.getFullYear(),0,1))/8.64e7)+1)/7);
			//CALCULATE WEEK OF ADJ
			date_adj = new Date(adj);
			date_adj.setHours(0,0,0)
			date_adj.setDate(date_adj.getDate()+4-(date_adj.getDay()||7));
			week_adj = Math.ceil((((date_adj-new Date(date_adj.getFullYear(),0,1))/8.64e7)+1)/7);

			//GET YEAR OF DATE ADJ
			var year = date_adj.getFullYear();
			var year_ent = date_ent.getFullYear();

			//GET NUMBER OF WEEKS
			n_week =  Math.max(
            	moment(new Date(year, 11, 31)).isoWeek()
          		, moment(new Date(year, 11, 31-7)).isoWeek()
   			);

   			if(year != year_ent)
   			{
   				total = n_week - week_adj + week_ent;

   			}
   			else
   			{
   				total = week_ent - week_adj;
   			}
   			return total;

		},
		//FUNCTION TO CALCULATE DIF OF PREGGO
		calDifPreggo: function(week, week_preggo) {
			return week_preggo - week;
		},
		SubtractDates: function(date1, date2) {

			date_1 = new Date(date1);
			date_2 = new Date(date2);

			var timeDiff = Math.abs(date_1.getTime() - date_2.getTime());
			return Math.ceil(timeDiff / (1000 * 3600 * 24));


		},
		calDifPreggo_VPREGGO: function(week, week_preggo) {
			d = new Date(week);
    		d.setHours(0,0,0);
    		d.setDate(d.getDate()+4-(d.getDay()||7));
    		week_cal = Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

			return week_preggo - week_cal;
		},
		calculateCurso: function(week, entrada) {
			if(entrada == "0000-00-00")
    		{
    			d = new Date();
    			d.setHours(0,0,0);
    			d.setDate(d.getDate()+4-(d.getDay()||7));
    			week_cal = Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

    			return week_cal - week;
    		}
    		else
    		{
    			return "DONE ✔";
    		}
		},
		checkResponsavel: function (responsavel) {
			if(responsavel == null)
			{
				return "NaN";
			}
			else
			{
				return responsavel;
			}
		},
		defineForn: function(forns) {
			if(forns == null)
			{
				return "NaN";
			}
			else
			{
				return forns;
			}
		},
		//BASIC SUM
		basicSum: function(param1, param2) {
			return param1 + param2;
		},
		DefinePick: function(param) {
			if(param == null)
			{
				return 0;
			}
			else
			{
				return param;
			}
		},
		DefineText: function(param) {
			if(param == "")
			{
				return "NaN";
			}
			else
			{
				return param;
			}
		},
		DefineDataPagamento: function(date, tipo_pagamento) {
			if(tipo_pagamento == "Pronto-Pagamento")
			{
				if(date != "0000-00-00") {
					date_treat = new Date(date);
					return date_treat;
				}
			}
			else if(tipo_pagamento == "30 Dias")
			{
				if(date != "0000-00-00") {
					date_treat = new Date(date);
					date_treat.setDate(date_treat.getDate() + 30);
					return date_treat;
				}
			}
			else if(tipo_pagamento == "60 Dias")
			{
				if(date != "0000-00-00") {
					date_treat = new Date(date);
					date_treat.setDate(date_treat.getDate() + 60);
					return date_treat;
				}
			}
			else if(tipo_pagamento == "90 Dias")
			{
				if(date != "0000-00-00") {
					date_treat = new Date(date);
					date_treat.setDate(date_treat.getDate() + 90);
					return date_treat;
				}
			}
			

		},
		DefinePenalizacao: function(value) {
			var sum = 0;
			for(var i = 1; i <= value; i++) {
				if(value <= 3) {
					sum += 1;
				}
				else if(value > 3)
				{
					sum += 2;
				}
			}
			return sum;
		},
		DefinePenalizacao_Preggo: function(param1, param2)
		{
			d = new Date(param2);
    		d.setHours(0,0,0);
    		d.setDate(d.getDate()+4-(d.getDay()||7));
    		week = Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

    		value = week - param1;

    		var sum = 0;
			for(var i = 1; i <= value; i++) {
				if(value <= 3) {
					sum += 1;
				}
				else if(value > 3)
				{
					sum += 2;
				}
			}
			return sum;


		},
		//BASIC SUBTRACT
		basicSubtract: function(param1, param2) {
			valor_parcial = param2 - param1;
			return Math.round(valor_parcial * 100) / 100;
		},
		basicMultiply: function(param1, param2){
			valor_parcial = param1 * param2;
			return Math.round(valor_parcial * 100) / 100;
		},
		basicDivision: function(param1, param2){
			valor_parcial = param1 / param2;

			return Math.round(valor_parcial * 100);
		},
		calculatePurchasePrice: function(value){

				return Math.round((value/1.2) * (0.6*0.6));


		},
		calculatePercentage: function(param1, param2, param3){
			valor_parcial = param1 - (param2 / 100 * param1);
			valor_parcial_final = valor_parcial - (param3 / 100 * valor_parcial);

			return Math.round(valor_parcial_final * 100) / 100;
		},
		calculatePrecoVenda: function(pvp, desconto_pvp, qtnd){

				valor_parcial = pvp - (desconto_pvp / 100 * pvp);
				return Math.round(valor_parcial * 100) / 100 * qtnd;

		},
		calculatePenalizacao: function(pvp, desconto_pvp){
			valor_parcial = pvp - (desconto_pvp / 100 * pvp);
			return Math.round(valor_parcial * 100) / 100;
			

		},
		//DEFINE CLASS OF DIF TO PUT RED OR DEFAULT
		defineClassDif: function(value) {
			if(value > 1)
			{
				return "warn_dif";
			}
		},
		//DEFINE THE CLASS OF DELAY 
		delayClass: function(value) {
			if(value <= -4)
			{
				return "positive_apply";
			}
			else if(value == -2 || value == -3)
			{
				return "yellow_apply";
			}
			else if(value == 0 || value == -1)
			{
				return "orange_apply";
			}
			else if(value >= 1)
			{
				return "danger_apply";
			}

		},
		//DEFINE THE CLASS OF CURSO
		cursoClass: function(value, entrada) {
			if(entrada != "0000-00-00")
			{
				return "done_apply";
			}
			else
			{
				if(value <= -4)
				{
				return "positive_apply";
				}
				else if(value == -2 || value == -3)
				{
				return "yellow_apply";
				}
				else if(value == 0 || value == -1)
				{
				return "orange_apply";
				}
				else if(value >= 1)
				{
				return "danger_apply";
				}
			}


		},
		changeRating: function(value, id) {
			orderModel.changeRating(value, id).success(function(){
			});
		},
		//IF DIMENSIONS == CUSTOM SET THE CUSTOM DIMENSIONS
		selectDimensions: function(t_medida, diameter, width, height, depth) {
			if(t_medida == "Custom")
			{
				if(diameter == null) {
					diameter = 0;
				}
				if(width == null) {
					width = 0;
				}
				if(height == null) {
					height = 0;
				}
				if(depth == null) {
					depth = 0;
				}
				
				var Custom = diameter + "x" + width + "x" + depth + "x" + height;
				return Custom;
			}
			else  {
				return t_medida;
			}
		},
		//FORM TO EDIT PREVISÂO PREGGO
		editPrediction: function (adjudication, id, previsao_preggo) {
			if(adjudication.$valid) {
				$scope.formSubmitted = false;
				orderModel.savePrediction(previsao_preggo, id).success(function(){
						//FUNCTION TO GET THE ADJUDICATIONS BASED ON TYPE OF USER
						ReloadData();
				});
			} else {
				$scope.formSubmitted = true;
				console.log('Error Prediction');
			}
		},
		changeEmbalagemStatus: function (embalagemStatus, id) {
			if(embalagemStatus == true)
			{
				orderModel.StatusEmbaladoChecked(id).success(function(){
				});
			}
			else
			{
				orderModel.StatusEmbaladoNOTChecked(id).success(function(){
				});
			}
		},
		changeTipoPagamento: function (state, id) {
			if(state == true)
			{
				orderModel.Pagamento30(id).success(function(){
					ReloadData();
				});
			}
			else
			{
				orderModel.Pagamento60(id).success(function(){
					ReloadData();
				});
			}
		},
		conferirEstado: function (state, id) {
			if(state == true)
			{
				orderModel.StateChecked(id).success(function(){
					ReloadData();
				});
			}
			else
			{
				orderModel.StateNOTChecked(id).success(function(){
					ReloadData();
				});
			}
		},
		removeArchive: function(id, row) {
		if (confirm("Tem certeza que pretende retornar a NP para as ativas?")) {
			orderModel.RemoveArquivo(id).success(function(){
				var index = $scope.Adjudications.indexOf(row);
        		if (index !== -1) {
            	$scope.Adjudications.splice(index, 1);
        		}
			});
			}
		},
		removeArchivePersonal: function(id, user_id, row) {
		if (confirm("Tem certeza que pretende retornar a NP para as ativas?")) {
			orderModel.RemoveArquivoPersonal(id, user_id).success(function(){
				var index = $scope.Adjudications.indexOf(row);
        		if (index !== -1) {
            	$scope.Adjudications.splice(index, 1);
        		}
			});
			}
		},
		removeOrder: function(id, row) {
			if (confirm("Tem certeza que pretende eliminar o item permanentemente?")) {
			orderModel.deleteOrder(id).success(function(){
					var index = $scope.Adjudications.indexOf(row);
        			if (index !== -1) {
            		$scope.Adjudications.splice(index, 1);
        			}
				});
			}
		},
		getStateOF: function(state) {
			if(state == 1){
				return "Conferido ✓"
			}
			else {
				return "Por Conferir"
			}

		},
		getStateOF2: function(state) {
			if(state == 1){
				return "Embalado ✓"
			}
			else {
				return "Por Embalar"
			}

		},
		refreshData: function() {
		ReloadData();
		},
		//PHASES
		goPhase: function(phase) {

			var old = $location.hash();
    		$location.hash(phase);
   			$anchorScroll();
    		$location.hash(old);

		},
		checkState: function(estado) {
			if(estado == 1)
			{
				return "confirmed";
			}

		},
		checkPO_STATE: function(estado) {
			if(estado == 1)
			{
				return "confirmed_over";
			}

		},
		checkEmbalagem: function (value) {
			if(value == 1)
			{
				return "checked";
			}

		},
		weekCompromisso: function (d, value) {
			d = new Date(d);
    		d.setHours(0,0,0);
    		d.setDate(d.getDate()+4-(d.getDay()||7));
    		var date = Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

    		return date + value;
		},
		calculateWeek: function(d, lt) {
			d = new Date(d);
    		d.setHours(0,0,0);
    		d.setDate(d.getDate()+4-(d.getDay()||7));
    		var date = Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

    		var weeks =  weeksInYear(d.getFullYear());

    		if(date + lt > weeks){
    			var parcial = (date + lt) - weeks;
    			return parcial;
    		}
    		else
    		{
    			return date + lt;
    		}

		},

		arquivarOrder: function(id, type, row, from) {
		if (confirm("Irá mover a NP para " + type + ", tem a certeza?")) {
			orderModel.arquivar(id, type).success(function(){
					if(from == "Cemitério")
					{
						if(type == "cemetery_good" || type == "cemetery_bad")
						{
							if(user.state == "vendedor")
							{
							orderModel.getArchiveOrdersPersonal(user.sigla, user.id, $routeParams.path).success(function(response){
							$scope.Adjudications = response;
							$scope.showAdjudications = true;
							});
							}
							else {
							orderModel.getArchiveOrders($routeParams.path).success(function(response){
							$scope.Adjudications = response;
							$scope.showAdjudications = true;

							});

							}
							//GET HIGHTLIGHTS OF CEMETERY
							orderModel.getArchiveCemetery().success(function(response) {
							$scope.CemeteryHighlights = response;
							});
						}
						else
						{
							var index = $scope.Adjudications.indexOf(row);
        					if (index !== -1) {
            				$scope.Adjudications.splice(index, 1);
        					}

        					//GET HIGHTLIGHTS OF CEMETERY
							orderModel.getArchiveCemetery().success(function(response) {
							$scope.CemeteryHighlights = response;
							});
						}
						
					}
					else
					{
						var index = $scope.Adjudications.indexOf(row);
        				if (index !== -1) {
            			$scope.Adjudications.splice(index, 1);
        				}
        			}
				});
			}
		},
		filterCemetery: function(type) {
			if(user.state == "vendedor")
			{
			orderModel.getArchiveOrdersPersonal(user.sigla, user.id, type).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			$scope.Adjudications= response_2;
			$scope.showAdjudications = true;
			});
			}
			else {
			orderModel.getArchiveOrders(type).success(function(response){
			var response_2 = _.sortBy(( _.sortBy(response, 'id')), 'PO');
			$scope.Adjudications= response_2;
			$scope.showAdjudications = true;

			});
			}


		},



		//MODALS
		//OPEN MODAL TO EDIT
  		openModalResponsavelPreggo : function (id) {
  		$scope.item = id;

    	var modalInstance = $uibModal.open({
      	animation: $scope.animationsEnabled,
      	templateUrl: 'templates/modals/edit_responsavel_preggo.html',
      	controller: 'modalControllerResponsavel',
      	resolve: {
        	item: function () {
          		return $scope.item;
        	}
      	}
    	});
    	modalInstance.result.then(function () {
       	orderModel.getArchiveOrders().success(function(response){
			$scope.Adjudications = response;

		});
    	}, function () {});

		},
		//OPEN MODAL OF STATUS PREGGO
		openModalStatus : function (id) {
  		$scope.item = id;

    	var modalInstance = $uibModal.open({
      	animation: $scope.animationsEnabled,
      	templateUrl: 'templates/modals/edit_status.html',
      	controller: 'modalControllerStatus',
      	resolve: {
        	item: function () {
          		return $scope.item;
        	}
      	}
    	});
    	modalInstance.result.then(function () {
      	orderModel.getArchiveOrders().success(function(response){
				$scope.Adjudications = response;

				});
    	}, function () {});

		},

		//OPEN MODAL TO ENTRADA ARMAZEM
		openModalEntradaArmazem : function (id) {
  		$scope.item = id;

    	var modalInstance = $uibModal.open({
      	animation: $scope.animationsEnabled,
      	templateUrl: 'templates/modals/phase3_edit.html',
      	controller: 'modalControllerEntrada_Armazem',
      	resolve: {
        	item: function () {
          		return $scope.item;
        	}
      	}
    	});
    	modalInstance.result.then(function () {
       orderModel.getArchiveOrders().success(function(response){
				$scope.Adjudications = response;

				});
    	}, function () {});

		},

		//OPEN MODAL TO FORNECEDORES
		openModalFornecedores : function (id) {
  		$scope.item = id;

    	var modalInstance = $uibModal.open({
      	animation: $scope.animationsEnabled,
      	templateUrl: 'templates/modals/fornecedores.html',
      	controller: 'modalControllerFornecedores',
      	resolve: {
        	item: function () {
          		return $scope.item;
        	}
      	}
    	});
    	modalInstance.result.then(function () {
       	orderModel.getArchiveOrders().success(function(response){
				$scope.Adjudications = response;

				});
    	}, function () {});

		},
		//OPEN MODAL TO EDIT PRODUCT
		openModalProduct : function (id) {
  		$scope.item = id;

    	var modalInstance = $uibModal.open({
      	animation: $scope.animationsEnabled,
      	templateUrl: 'templates/modals/edit_order_product.html',
      	controller: 'modalControllerEditProduct',
      	resolve: {
        	item: function () {
          		return $scope.item;
        	}
      	}
    	});
    	modalInstance.result.then(function () {
       		refreshData();
    	}, function () {});

		},





	});
}]);