myApp.controller('calendarController', ['$scope', '$location', '$cookies', 'calendarModel', function($scope, $location, $cookies, calendarModel){
	
	var user = $cookies.getObject('auth');
	
	function getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0,0,0);
    d.setDate(d.getDate() + 4 - (d.getDay()||7));
    var yearStart = new Date(d.getFullYear(),0,1);
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7)
    return weekNo;
    }
    //FUNCTION WEEKS BASED ON YEAR
    function weeksInYear(year) {
    var month = 11, day = 31, week;

    do {
        d = new Date(year, month, day--);
        week = getWeekNumber(d);
    } while (week == 1);

    return week;
    }

    /* GET QUERY */
	var year = new Date().getFullYear();
    var today = new Date();
    $scope.actual_year = year;
    var yearStart = 2015;
    $scope.object_years = [];
    $scope.object_weeks = [];

    while(yearStart < year + 1){
        $scope.object_years.push(yearStart++);
    }
    var i = 1;



    $scope.atual_week = getWeekNumber(today);
    var atual_week = getWeekNumber(today);
    $scope.weeks = weeksInYear(year);
    var weeks = weeksInYear(year);
    while(i < weeks + 1) {
        $scope.object_weeks.push(i++)
    }

    $scope.Reading = [
    {value: 'Supplier'},
    {value: 'Wish'},
    {value: 'Promisse'},
  	];

  	$scope.changeReading = "Supplier";

  	$scope.types = [
  	{value: 'Total'},
  	{value: 'Clientes'},
  	{value: 'Stock'}
  	];

  	$scope.changeType = "Total";


	/*Variables */
	angular.extend($scope, {
	changeYear : "" + year + "",
    changeWeek: "" + atual_week + "",
    fixedYear: year,
	Week: atual_week,
	nextWeek : atual_week + 1,
    nextWeek_2 : atual_week + 2,
    nextWeek_3 : atual_week + 3,

    //VARIABLES OF SUMS BY WEEKS
    sumDanger_t1 : 0,
    sumOrange_t1: 0,
    sumYellow_t1: 0,
    sumGreen_t1: 0,

    //VARIABLES OF SUMS BY WEEKS TABLE 2
    sumDanger_t2: 0,
    sumOrange_t2: 0,
    sumYellow_t2: 0,
    sumGreen_t2: 0,
	
	});

	// BASE FUNCTIONS TO CONSTRUCT THE TABLES
	calendarModel.getAtualWeek(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data){
        $scope.Adjudications = data;

        var orderedAdj = _.groupBy($scope.Adjudications, "PO");
        $scope.sum_PO = _.size(orderedAdj);

        angular.forEach(data, function(value, key) {
        	switch (true) {
        		case (value.week_c <= -4):
        		$scope.sumGreen_t1 += 1;
        		break;

        		case (value.week_c == -2 || value.week_c == -3):
        		$scope.sumYellow_t1 += 1;
        		break;

        		case (value.week_c == 0 || value.week_c == -1):
        		$scope.sumOrange_t1 += 1;
        		break;

        		case (value.week_c >= 1):
        		$scope.sumDanger_t1 += 1;
        		break;
        	}
        	
  			
		});

    });

	calendarModel.getNextWeek(atual_week, year, $scope.changeReading, $scope.changeType).success(function(data) {
		$scope.Adjudications_next_week = data;

		var orderedAdj_week = _.groupBy($scope.Adjudications_next_week, "PO");
		$scope.sum_PO_next_week = _.size(orderedAdj_week);

		angular.forEach(data, function(value, key) {
        	switch (true) {
        		case (value.week_c <= -4):
        		$scope.sumGreen_t2 += 1;
        		break;

        		case (value.week_c == -2 || value.week_c == -3):
        		$scope.sumYellow_t2 += 1;
        		break;

        		case (value.week_c == 0 || value.week_c == -1):
        		$scope.sumOrange_t2 += 1;
        		break;

        		case (value.week_c >= 1):
        		$scope.sumDanger_t2 += 1;
        		break;
        	}
        	
  			
		});

		
	});

	function refreshData(changeWeek, changeYear, type_reading, type_po) {

      calendarModel.getAtualWeek(changeWeek, changeYear, type_reading, type_po).success(function(data){
        $scope.Adjudications = data;

        var orderedAdj = _.groupBy($scope.Adjudications, "PO");
        $scope.sum_PO = _.size(orderedAdj);

        $scope.sumGreen_t1 = 0;
        $scope.sumYellow_t1 = 0;
        $scope.sumOrange_t1 = 0;
        $scope.sumDanger_t1 = 0;

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t1 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t1 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t1 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t1 += 1;
                break;
            }
            
            });

        }
        else
        {
            angular.forEach(data, function(value, key) {
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t1 += 1;
                break;

                case (value.week_c == 5 || value.week_c == 6):
                $scope.sumYellow_t1 += 1;
                break;

                case (value.week_c == 7 || value.week_c == 8):
                $scope.sumOrange_t1 += 1;
                break;

                case (value.week_c >= 9):
                $scope.sumDanger_t1 += 1;
                break;
            }
            
            });
        }


    });

	calendarModel.getNextWeek(changeWeek, changeYear, type_reading, type_po).success(function(data) {
		$scope.Adjudications_next_week = data;

		var orderedAdj_week = _.groupBy($scope.Adjudications_next_week, "PO");
		$scope.sum_PO_next_week = _.size(orderedAdj_week);

		$scope.sumGreen_t2 = 0;
        $scope.sumYellow_t2 = 0;
        $scope.sumOrange_t2 = 0;
        $scope.sumDanger_t2 = 0;

        if(type_reading == "Supplier" || type_reading == "Promisse")
        {
            angular.forEach(data, function(value, key) {
            switch (true) {
                case (value.week_c <= -4):
                $scope.sumGreen_t2 += 1;
                break;

                case (value.week_c == -2 || value.week_c == -3):
                $scope.sumYellow_t2 += 1;
                break;

                case (value.week_c == 0 || value.week_c == -1):
                $scope.sumOrange_t2 += 1;
                break;

                case (value.week_c >= 1):
                $scope.sumDanger_t2 += 1;
                break;
            }
            
            
            });
        }
        else
        {
            angular.forEach(data, function(value, key) {
            switch (true) {
                case (value.week_c <= 4):
                $scope.sumGreen_t2 += 1;
                break;

                case (value.week_c == 5 || value.week_c == 6):
                $scope.sumYellow_t2 += 1;
                break;

                case (value.week_c == 7 || value.week_c == 8):
                $scope.sumOrange_t2 += 1;
                break;

                case (value.week_c >= 9):
                $scope.sumDanger_t2 += 1;
                break;
            }
            
            });
        }

	});
    }



	angular.extend($scope, {
		defineClass: function(week, type) {
			if(type == "Supplier" || type == "Promisse")
			{
				switch (true) {
					case (week <= -4):
					return "positive_apply_calendar";
					break;

					case (week == -2 || week == -3):
					return "yellow_apply_calendar";
					break;

					case (week == 0 || week == -1):
					return "orange_apply_calendar";
					break;

					case (week >= 1):
					return "danger_apply_calendar";
					break;
				}
			}
			else
			{
				switch (true) {
					case (week >= 9):
					return "danger_apply_calendar";
					break;

					case (week == 7 || week == 8):
					return "orange_apply_calendar";
					break;

					case (week == 5 || week == 6):
					return "yellow_apply_calendar";
					break;

					case (week <= 4):
					return "positive_apply_calendar";
					break;
				}

			}

		},
		changeData: function(week, year, type_reading, type_po) {
            
            refreshData(week, year, type_reading, type_po);

            //reload variables of weeks
            $scope.nextWeek = parseInt(week) + 1;
            $scope.nextWeek_2 = parseInt(week) + 2;
            $scope.nextWeek_3 = parseInt(week) + 3;

            if(type_reading == 'Promisse') 
            {
                $scope.changeType = "Clientes";

            }
		},

		PrintTable: function(table) {
        var divToPrint=document.getElementById(table);

        if(table == "table_1")
        {
        	 var week = $("#changeWeek").val();
        }
        else
        {
        	 var week = $("#changeWeek").val();
        	 week = parseInt(week) + 1;
        }
        var year = $("#changeYear").val();
        var read = $("#changeReading").val();
        var type = $("#changeType").val();

        newWin= window.open("");
        newWin.document.write('<html>');
        newWin.document.write('<head><link rel="stylesheet" type="text/css" href="' + baseUrl + 'css/print-calendar.css"></head>');
        newWin.document.write('<body>');
        newWin.document.write('<div style="text-align:center"><img src="' + baseUrl +'images/logo.png" width="200" height="30" /></div>');
        newWin.document.write('<div class="title-page">Calendário Semanal Produção Semana <b>' + week + '</b> de <b> ' + year + '</b> previsto em <b>' + read + '</b> do tipo <b>' + type + '</b></div>');
        newWin.document.write(divToPrint.outerHTML);
        newWin.document.write('</body></html>');

        //GET THE BROWSER 
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isSafari == true) {
            $(newWin).ready(function() {  
         setTimeout(
            function(){
            newWin.print();
            newWin.close();
            },(300));
        });
        }
        else if(isFirefox == true){
         newWin.print();
         newWin.close();
        }
        else if(isIE == true) {
            newWin.print();
        }
        else if(isChrome == true){
            $(newWin).ready(function() {  
                setTimeout(
                    function(){
                    newWin.print();
                    newWin.close();
                },(600));
            });
        }

        }
	
	
	});

}]);