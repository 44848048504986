myApp.controller('imparidadeController', ['$scope', '$location', '$cookies', '$uibModal', 'imparidadeModel', function($scope, $location, $cookies, $uibModal, imparidadeModel){
	
	var user = $cookies.getObject('auth');

	imparidadeModel.getImparidades().success(function(response){
		$scope.imparidades = response;
		$scope.showImparidades = true;
	});

	imparidadeModel.getImparidadesDetails().success(function(response){
		$scope.imparidades_details = response;
	});

	$scope.per_page = [
  	{value: 10, text: 10},
  	{value: 20, text: 20},
  	{value: 30, text: 30},
  	{value: 50, text: 50},
	];
	$scope.select_page = "10";

	function refreshData() {
		imparidadeModel.getImparidades().success(function(response){
		angular.extend($scope.imparidades, response);
		});

		imparidadeModel.getImparidadesDetails().success(function(response){
		$scope.imparidades_details = response;
		});
	
	}
	function refreshData_reload() {
		imparidadeModel.getImparidades().success(function(response){
		$scope.imparidades = response;
		});

		imparidadeModel.getImparidadesDetails().success(function(response){
		$scope.imparidades_details = response;
		});
	
	}
  

	/*Variables */
	angular.extend($scope, {
		today: new Date(),
	
	});


	angular.extend($scope, {
	
	defineId: function(value, value2) {
		return value - value2;
	},	
	//OPEN MODAL TO ADD IMPARIDADE
  	openAddImparidade: function () {

  	var modalInstance = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'templates/modals/add_imparidade.html',
      controller: 'modalControllerAddImparidade',

  	});
  	modalInstance.result.then(function () {
       refreshData();
		});
    },

    openEditImparidade : function (id) {
  	$scope.item = id;

    var modalInstance = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'templates/modals/edit_imparidade.html',
      controller: 'modalControllerEditImparidade',
      resolve: {
        item: function () {
          return $scope.item;
        }
      }
    });
    modalInstance.result.then(function () {
      refreshData();
    });

	},
	deleteImparidade: function (id, row) {
	if (confirm("Are you sure you want to delete?")) {
        imparidadeModel.deleteImparidade(id).success(function(){
			refreshData_reload();
		});
    	}
	},

		
		
	});

}]);