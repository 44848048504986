myApp.controller('addRegisterController', ['$scope', '$location', '$compile', 'orderModel', 'costumerModel', '$timeout', '$routeParams', 'Notification', '$route',
	function ($scope, $location, $compile, orderModel, costumerModel, $timeout, $routeParams, Notification, $route) {
	
	//NOTIFICATIONS CALL
	//
	/* GET PRODUCTS */


	orderModel.getProducts().success(function(response){
		$scope.products = response;
		$scope.showProducts = true;

	});

	/* GET CRs */
	costumerModel.getCostumers().success(function(response){
		$scope.costumers = response;
		$scope.showCostumers = true;

	});

	var Produto_count = 1;
	var Produto_count_ins = 0;

	$scope.DefaultDim = [{0:true}];
	$scope.p_diameter = [];
	$scope.p_width = [];
	$scope.p_height = [];
	$scope.p_depth = [];
	$scope.p_VP = [];


	/*Variables */
	angular.extend($scope, {
		newOrder: {},
		errorDiv: false,
		errorMessages: [],
		Produto_add: {NP:[], Acabamentos:[]},
		showDimension: [],
	});

	/* Functions */
	angular.extend($scope, {
		//SAVE REGISTER
		saveNewAdjudication: function (addAdjudication) {
		orderModel.saveAdjudication($scope.newOrder,$scope.p_diameter, $scope.p_width, $scope.p_height, $scope.p_depth, $scope.Produto_add).success(function(){
				Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; PO Inserida');
				$route.reload();
		});
			
		},
		//SAVE STOCK
		saveNewStock: function (addAdjudication) {
		orderModel.saveStock($scope.newOrder,$scope.p_diameter, $scope.p_width, $scope.p_height, $scope.p_depth, $scope.p_VP, $scope.Produto_add).success(function(){
				Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; PO de Stock Inserida');
				$route.reload();
		});
			
		},
		//GET DIMENSIONS
		DimensionsTake: function (produto, num) {
			orderModel.getDimensions(produto).success(function(response){
				$scope.showDimension[num] = true;
				$scope.p_diameter[num] = response[0].diameter;
				$scope.p_width[num] = response[0].width;
				$scope.p_height[num]= response[0].height;
				$scope.p_depth[num]= response[0].depth;
				$scope.p_VP[num]= response[0].valor_producao;
				
			});
		},
		//PUT DISABLE IF DEFAULT
		ChangeCustom: function(type, num) {
			if(type == "Custom")
			{
				$scope.DefaultDim[num] = false;
			}
			else
			{
				$scope.DefaultDim[num] = true;
			}
		},
		addNewElement: function()  {
		Produto_count += 1;
		Produto_count_ins += 1;
		$scope.DefaultDim.push({Produto_count_ins: true});
		//SELECT OF PRODUCT
		var Content = '<div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" style="margin-bottom: 10px;" id="product_' + Produto_count_ins + '"><fieldset><legend>Produto ' + Produto_count + ':</legend><div class="form-group col-md-12" ><label>Produto</label><ol class="nya-bs-select" data-live-search="true" data-size="15" name="orderNomeProduto_' + Produto_count_ins + '" id="orderNomeProduto_' + Produto_count_ins + '" class="form-control" ng-model="Produto_add.NomeProduto[' + Produto_count_ins + ']" ng-change="DimensionsTake(Produto_add.NomeProduto[' + Produto_count_ins + '], ' + Produto_count_ins + ')" required><li nya-bs-option="product in products | orderBy:p_id" ng-show="showProducts" data-value="product.id" title="{{product.collection}} {{product.name}} {{product.categoria}} {{product.version}} ({{product.finish}})"> <a><b>{{product.collection}}</b> {{product.name}} {{product.categoria}} {{product.version}} ({{product.finish}})</a></li></ol></div>';
		//TIPO DE MEDIDAS
		Content += '<div class="form-group col-md-12" ><label>Tipo de Medidas</label><select class="form-control" name="orderTypeDimensions_' + Produto_count_ins + '" ng-change="ChangeCustom(Produto_add.TypeDimensions[' + Produto_count_ins + '], ' + Produto_count_ins + ')" id="orderTypeDimensions_' + Produto_count_ins + '" ng-model="Produto_add.TypeDimensions[' + Produto_count_ins + ']" required><option value="" disabled>Escolha o tipo de Medida</option><option value="Standard"> Standard </option><option value="Custom"> Custom </option></select></div>';
		//MEDIDAS
		Content += '<div ng-show="showDimension[' + Produto_count_ins + ']"><div class="form-group col-sm-3"><label>Diameter</label><input type="number" string-to-number class="form-control" name="orderDiameter" id="orderDiameter" ng-model="p_diameter[' + Produto_count_ins + ']" ng-value="p_diameter[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']" ></div><div class="form-group col-sm-3"><label>Width</label><input type="number" string-to-number class="form-control" name="orderWidth" id="orderWidth" ng-model="p_width[' + Produto_count_ins + ']" ng-value="p_width[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']"></div><div class="form-group col-sm-3"><label>Depth</label><input type="number" string-to-number class="form-control" name="orderDepth" id="orderDepth" ng-model="p_depth[' + Produto_count_ins + ']" ng-value="p_depth[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']"></div><div class="form-group col-sm-3"><label>Height</label><input type="number" string-to-number class="form-control" name="orderHeight" id="orderHeight" ng-model="p_height[' + Produto_count_ins + ']" ng-value="p_height[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']" ></div></div>';
		//NP
		Content += '<div class="form-group col-md-12" ><label>Nota de Produção</label><input type="text" class="form-control" placeholder="campo opcional.." name="orderNP_' + Produto_count_ins + '" id="orderNP_' + Produto_count_ins + '" ng-model="Produto_add.NP[' + Produto_count_ins + ']"></div>';
		//QUANTIDADE
		Content += '<div class="form-group col-md-12" ><label>Quantidade</label><input type="number" class="form-control" placeholder="inserir quantidade.." name="orderQtnd_' + Produto_count_ins + '" id="orderQtnd_' + Produto_count_ins + '" ng-model="Produto_add.Qtnd[' + Produto_count_ins + ']"  required><p ng-show="addAdjudication.orderQtnd_' + Produto_count_ins + '.$invalid && formSubmitted" class="help-block"> Quantidade é requerido. </p></div>';
		//TIPO MEDIDAS
		Content += '<div class="form-group col-md-12" ><label>Tipologia do Produto</label><select class="form-control" name="orderTipoProd_' + Produto_count_ins + '" id="orderTipoProd_' + Produto_count_ins + '" ng-model="Produto_add.TipoProd[' + Produto_count_ins + ']" required><option value="" disabled>Escolha a Tipologia do Produto</option><option value="STD ✓"> STD ✓</option><option value="Cst"> Cst </option><option value="RCM ☠"> RCM ☠</option><option value="C FLZ :)">C FLZ :)</option><option value="SMP">SMP</option><option value="Prt ♥ Prototipo"> Prt ♥ Prototipo</option><option value="Outros">Outros</option></select></div>';
		//ACABAMENTOS
		Content += '<div class="form-group col-md-12" ><label>Acabamentos / Especificações Extra</label><textarea rows="3" class="form-control" placeholder="campo opcional.." name="orderAcabamentos_' + Produto_count_ins + '" id="orderAcabamentos_' + Produto_count_ins + '" ng-model="Produto_add.Acabamentos[' + Produto_count_ins + ']" ></textarea></div>';
		
		Content += '</fieldset></div>';
		var temp = $compile(Content)($scope);
		angular.element(document.getElementById('product_content')).append(temp);

		},

		removeElement: function() {
			if(Produto_count_ins >= 1)
			{
			angular.element(document.getElementById('product_' + Produto_count_ins)).remove();
			Produto_count -= 1;
			Produto_count_ins -= 1;
			}
		},

		addNewElementStock: function()  {
		Produto_count += 1;
		Produto_count_ins += 1;
		$scope.DefaultDim.push({Produto_count_ins: true});
		//SELECT OF PRODUCT
		var Content = '<div class="col-md-6 col-lg-6 col-sm-6 col-xs-12" style="margin-bottom: 10px;" id="product_' + Produto_count_ins + '"><fieldset><legend>Produto ' + Produto_count + ':</legend><div class="form-group col-md-12" ><label>Produto</label><ol class="nya-bs-select" data-live-search="true" data-size="15" name="orderNomeProduto_' + Produto_count_ins + '" id="orderNomeProduto_' + Produto_count_ins + '" class="form-control" ng-model="Produto_add.NomeProduto[' + Produto_count_ins + ']" ng-change="DimensionsTake(Produto_add.NomeProduto[' + Produto_count_ins + '], ' + Produto_count_ins + ')" required><li nya-bs-option="product in products | orderBy:p_id" ng-show="showProducts" data-value="product.id" title="{{product.collection}} {{product.name}} {{product.categoria}} {{product.version}} ({{product.finish}})"> <a><b>{{product.collection}}</b> {{product.name}} {{product.categoria}} {{product.version}} ({{product.finish}})</a></li></ol></div>';
		//TIPO DE STOCK
		Content += '<div class="form-group col-md-12"><label>Tipo de Stock</label><select class="form-control" name="orderArchive_' + Produto_count_ins + '" ng-model="Produto_add.TypeArchive[' + Produto_count_ins + ']" required><option value="" disabled>Escolha o tipo de Stock</option><option value="2"> Stock </option><option value="6"> Cemitério Bom </option><option value="7"> Cemitério Mau </option></select></div>';
		//TIPO DE MEDIDAS
		Content += '<div class="form-group col-md-12" ><label>Tipo de Medidas</label><select class="form-control" name="orderTypeDimensions_' + Produto_count_ins + '" ng-change="ChangeCustom(Produto_add.TypeDimensions[' + Produto_count_ins + '], ' + Produto_count_ins + ')" id="orderTypeDimensions_' + Produto_count_ins + '" ng-model="Produto_add.TypeDimensions[' + Produto_count_ins + ']" required><option value="" disabled>Escolha o tipo de Medida</option><option value="Standard"> Standard </option><option value="Custom"> Custom </option></select></div>';
		//MEDIDAS
		Content += '<div ng-show="showDimension[' + Produto_count_ins + ']"><div class="form-group col-sm-3"><label>Diameter</label><input type="number" string-to-number class="form-control" name="orderDiameter" id="orderDiameter" ng-model="p_diameter[' + Produto_count_ins + ']" ng-value="p_diameter[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']" ></div><div class="form-group col-sm-3"><label>Width</label><input type="number" string-to-number class="form-control" name="orderWidth" id="orderWidth" ng-model="p_width[' + Produto_count_ins + ']" ng-value="p_width[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']"></div><div class="form-group col-sm-3"><label>Depth</label><input type="number" string-to-number class="form-control" name="orderDepth" id="orderDepth" ng-model="p_depth[' + Produto_count_ins + ']" ng-value="p_depth[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']"></div><div class="form-group col-sm-3"><label>Height</label><input type="number" string-to-number class="form-control" name="orderHeight" id="orderHeight" ng-model="p_height[' + Produto_count_ins + ']" ng-value="p_height[' + Produto_count_ins + ']" ng-disabled="DefaultDim[' + Produto_count_ins + ']" ></div><div class="form-group col-sm-12"><label>Valor Produção</label><input type="number" string-to-number class="form-control" name="orderVP" id="orderVP" ng-model="p_VP[' + Produto_count_ins + ']" ng-value="p_VP[' + Produto_count_ins + ']" ></div></div>';
		//NP
		Content += '<div class="form-group col-md-12" ><label>Nota de Produção</label><input type="text" class="form-control" placeholder="campo opcional.." name="orderNP_' + Produto_count_ins + '" id="orderNP_' + Produto_count_ins + '" ng-model="Produto_add.NP[' + Produto_count_ins + ']"></div>';
		//QUANTIDADE
		Content += '<div class="form-group col-md-12" ><label>Quantidade</label><input type="number" class="form-control" placeholder="inserir quantidade.." value="1" disabled  required><p ng-show="addAdjudication.orderQtnd_' + Produto_count_ins + '.$invalid && formSubmitted" class="help-block"> Quantidade é requerido. </p></div>';
		//TIPO MEDIDAS
		Content += '<div class="form-group col-md-12" ><label>Tipologia do Produto</label><select class="form-control" name="orderTipoProd_' + Produto_count_ins + '" id="orderTipoProd_' + Produto_count_ins + '" ng-model="Produto_add.TipoProd[' + Produto_count_ins + ']" required><option value="" disabled>Escolha a Tipologia do Produto</option><option value="STD ✓"> STD ✓</option><option value="Cst"> Cst </option><option value="RCM ☠"> RCM ☠</option><option value="C FLZ :)">C FLZ :)</option><option value="SMP">SMP</option><option value="Prt ♥ Prototipo"> Prt ♥ Prototipo</option><option value="Outros">Outros</option></select></div>';
		//ACABAMENTOS
		Content += '<div class="form-group col-md-12" ><label>Acabamentos / Especificações Extra</label><textarea rows="3" class="form-control" placeholder="campo opcional.." name="orderAcabamentos_' + Produto_count_ins + '" id="orderAcabamentos_' + Produto_count_ins + '" ng-model="Produto_add.Acabamentos[' + Produto_count_ins + ']" ></textarea></div>';
		
		Content += '</fieldset></div>';
		var temp = $compile(Content)($scope);
		angular.element(document.getElementById('product_content')).append(temp);

		},

		removeElementStock: function() {
			if(Produto_count_ins >= 1)
			{
			angular.element(document.getElementById('product_' + Produto_count_ins)).remove();
			Produto_count -= 1;
			Produto_count_ins -= 1;
			}
		}

	

		

	});


}]);