myApp.controller('userController', ['$scope', '$location', '$cookies', 'userModel',  function($scope, $location, $cookies, userModel){
	

	angular.extend($scope, {
		doLogin: function(loginForm) {
			$scope.hasError = false;
			var data = {
					email: $scope.login.username,
					password: $scope.login.password
			};
			userModel.doLogin(data).then(function() {
				var user = $cookies.getObject('auth');
				if(user.state == "preggo"){
					$location.path('/performance/problem');
				}
				else if(user.state == "vendedor")
				{
					$location.path('/orders/manage_salesman');
				}
				else if(user.cr == 1) {
					$location.path('/performance/problem');
				}
				else
				{
				
				$location.path('dashboard');

				
				}
				
			}, function errorCallback(response) {
    		$scope.hasError = true;
 			});
		},

		
		
	});

}]);