myApp.controller('productController', ['$scope', '$filter', '$uibModal','$location', 'orderModel', 'productModel', '$timeout', '$routeParams', 
	function ($scope, filter, $uibModal, $location, orderModel, productModel, $timeout, $routeParams) {
	
	orderModel.getProducts().success(function(response){
		$scope.products = response; 
		$scope.showProducts = true;
	});


	/* Functions */
	angular.extend($scope, {
		deleteProduct: function (id) {
			 if (confirm("Are you sure you want to delete?")) {
        		productModel.deleteProduct(id).success(function(response){
				$scope.products = response;	
			});
    	}
		},


	});

	/* MODAL DIALOG TO EDIT */
	$scope.animationsEnabled = true;


	//OPEN MODAL TO EDIT
  	$scope.openEditProduct = function (id) {
  	$scope.item = id;

    var modalInstance = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'templates/modals/edit_product.html',
      controller: 'modalController',
      resolve: {
        item: function () {
          return $scope.item;
        }
      }
    });
    modalInstance.result.then(function () {
      orderModel.getProducts().success(function(response){
		  $scope.products = response;
	 });
    }, function () {});

	}

	//OPEN MODAL TO ADD NEW PRODUCT
  $scope.openAddProduct = function () {

  var modalInstance = $uibModal.open({
      animation: $scope.animationsEnabled,
      templateUrl: 'templates/modals/add_product.html',
      controller: 'modalControllerAdd'

  });
  modalInstance.result.then(function () {
        orderModel.getProducts().success(function(response){
		$scope.products = response;
	});
    }, function () {});

	}


}]);