myApp.controller('spvArchivedController', ['$scope', '$cookies', 'spvModel', '$uibModal', 'Notification', '$route',
	function ($scope, $cookies, spvModel, $uibModal, Notification, $route) {
	
	var user = $cookies.getObject('auth');

	$scope.user_state = user.state;
	$scope.user_email = user.email;
	$scope.email_permited = 'tiagoreis@bocadolobo.com';

	spvModel.getReclamations_Archived().success(function(response){
		$scope.Reclamations = response;
		$scope.showReclamations = true;

	});
	//GET NUMBER OF RECLAMATIONS 
	spvModel.getAnaliseCount().success(function(response){
		$scope.AnaliseCount = response;
	});
	spvModel.getCursoCount().success(function(response){
		$scope.CursoCount = response;
	});
	spvModel.getReprovedCount().success(function(response){
		$scope.ReprovedCount = response;
	});

	/*Variables */
	angular.extend($scope, {
		today: new Date(),
		
	
	});

	function refreshData() {
		spvModel.getReclamations_Archived().success(function(response){
		$scope.Reclamations = response;
		});
		spvModel.getAnaliseCount().success(function(response){
		$scope.AnaliseCount = response;
		});
		spvModel.getCursoCount().success(function(response){
		$scope.CursoCount = response;
		});
		spvModel.getReprovedCount().success(function(response){
		$scope.ReprovedCount = response;
		});

	}
	function refreshData_extend() {
		spvModel.getReclamations_Archived().success(function(response){
		angular.extend($scope.Reclamations, response);
		});
	}

	/* Functions */
	angular.extend($scope, {
		deleteCase: function(id, row) {
			if (confirm("Irá Eliminar o Caso " + id + " permanentemente, tem a certeza?")) {
				spvModel.Eliminar_Caso(id).success(function(){
					var index = $scope.Reclamations.indexOf(row);
        			if (index !== -1) {
            		$scope.Reclamations.splice(index, 1);
        			}

        			spvModel.getAnaliseCount().success(function(response){
						$scope.AnaliseCount = response;
					});
					spvModel.getCursoCount().success(function(response){
						$scope.CursoCount = response;
					});
					spvModel.getReprovedCount().success(function(response){
						$scope.ReprovedCount = response;
					});
				});

			}
		},
		filterReclamation: function(text) {
			spvModel.getReclamations_Filter(text).success(function(response){
				$scope.Reclamations = response;

			});
		},
		filterReclamationDate: function(date_start, date_end) {
			spvModel.getReclamations_Filter_Date(date_start, date_end).success(function(response){
				$scope.Reclamations = response;
			});
		},
	

		// ##### MODALS ###### //
		openViewFiles : function (id) {
  			$scope.item = id;

    		var modalInstance = $uibModal.open({
      		animation: $scope.animationsEnabled,
      		templateUrl: 'templates/modals/spv_view_files.html',
      		controller: 'modalControllerViewFiles',
      		resolve: {
        		item: function () {
          		return $scope.item;
        		}
      		}
    		});
		},
	

	});

}]);