myApp.controller('settingsController', ['$scope', '$location', '$cookies', 'userSettings', function($scope, $location, $cookies, userSettings){
	
	var user = $cookies.getObject('auth');
	var id_user = user.id;

	userSettings.getUserInfo(id_user).success(function(response){
		$scope.user_info = response;
	});



	/*Variables */
	angular.extend($scope, {
		Updated: false,
	
	});


	angular.extend($scope, {
		updateUser: function(addUser) {
			if(addUser.$valid) {
				if(addUser.NewPassword.$modelValue != addUser.repeatNewPassword.$modelValue)
				{
					$scope.passMatch = true;
				}
				else
				{
					$scope.passMatch = false;
					$scope.formSubmitted = false;
					userSettings.saveInfo($scope.user_info, id_user).success(function(){
						$scope.user_info.NewPassword = null;
						$scope.user_info.repeatNewPassword = null;
						$scope.Updated = true;
					});
				}
				
			} else {
				$scope.formSubmitted = true;
				console.log('Error');
			}
		},
		
		
	});

}]);