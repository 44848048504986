myApp.controller('dashboardController', ['$scope', '$location', '$cookies', '$timeout', 'dashboardModel', '$routeParams',
	function ($scope, $location, $cookies, $timeout, dashboardModel,  $routeParams) {
	
    var user = $cookies.getObject('auth');

    //NOTIFICATIONS CALL
    /*if(user.state == "admin")
    {
    $scope.get_count();
    }*/
	
    /* GET QUERY */
	var year = new Date().getFullYear();
    var local = "Total";
    $scope.actual_year = year;
    var yearStart = 2016;
    $scope.object_years = [];

    while(yearStart < year + 1){
        $scope.object_years.push(yearStart++);
    }

    if(user.state == "vendedor")
    {

    dashboardModel.getGraphSpec_Vendedor(year, local, user.sigla).success(function(data){

        $scope.data_po_graph = data.adjudications;
        var names = [];
        var values = [];
        $scope.active_PO = 0;
        $scope.final_PO = 0;

        for(var i = 0; i < data.adjudications.length; i++) {
        var obj = data.adjudications[i];

        if(obj.specifications != undefined) {
            names.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values.push(obj.total);
            $scope.active_PO += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PO += obj.valor;
        }
        }


        $scope.labels = names;
        $scope.data = values;
        $scope.atraso = data.delay;
        $scope.avg_entrega = data.avg_entrega;
        $scope.avg_atraso = data.avg_atraso;

    });

    /* Construct graph type of specs */
    /* Construct graph Peças Quantidade */
    dashboardModel.getGraphTypeProductSum_Vendedor(year, local, user.sigla).success(function(data){

        $scope.data_pecas_qtnd_graph = data;
        var names_type_product_sum = [];
        var values_type_product_sum = [];
        $scope.pecas_QUANTIDADE = 0;
        $scope.final_QTND = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_sum.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_sum.push(obj.total);
            $scope.pecas_QUANTIDADE += Number(obj.total);
        }
        if(obj.valor != undefined) {
            $scope.final_QTND += obj.valor;
        }
        }
         $scope.labels_type_product_sum = names_type_product_sum;
         $scope.data_type_product_sum = values_type_product_sum;

    });

    /* 2nd PHASE DASHBOARD */
    dashboardModel.getGraphTotalCliente_Vendedor(year, local, user.sigla).success(function(data){

        $scope.data_total_cliente_LEAD = data;
        var names_total_cliente = [];
        var values_total_cliente = [];
        $scope.pecas_total_cliente = 0;
        $scope.final_valor_cliente = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_cliente.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_cliente.push(obj.total);
            $scope.pecas_total_cliente += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_cliente += obj.valor;
        }
        }
         $scope.labels_total_cliente = names_total_cliente;
         $scope.data_total_cliente = values_total_cliente;

    });
    dashboardModel.getGraphTypeProduct_Vendedor(year, local, user.sigla).success(function(data){

        $scope.data_pecas_graph = data;
        var names_type_product = [];
        var values_type_product = [];
        $scope.pecas_CLIENTE = 0;
        $scope.final_PECA = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product.push(obj.total);
            $scope.pecas_CLIENTE += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA += obj.valor;
        }
        }
         $scope.labels_type_product = names_type_product;
         $scope.data_type_product = values_type_product;

    });

    /* 3rd PHASE DASHBOARD */
    dashboardModel.getGraphTotalStock_Vendedor(year, local, user.sigla).success(function(data){
        $scope.data_total_stock_LEAD = data;
        var names_total_stock = [];
        var values_total_stock = [];
        $scope.pecas_total_stock = 0;
        $scope.final_valor_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_stock.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_stock.push(obj.total);
            $scope.pecas_total_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_stock += obj.valor;
        }
        }
         $scope.labels_total_stock = names_total_stock;
         $scope.data_total_stock = values_total_stock;

    });

    dashboardModel.getGraphTypeProductStock_Vendedor(year, local, user.sigla).success(function(data){
        $scope.data_pecas_graph_stock = data;
        var names_type_product_stock = [];
        var values_type_product_stock = [];
        $scope.pecas_CLIENTE_stock = 0;
        $scope.final_PECA_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_stock.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_stock.push(obj.total);
            $scope.pecas_CLIENTE_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA_stock += obj.valor;
        }
        }
         $scope.labels_type_product_stock = names_type_product_stock;
         $scope.data_type_product_stock = values_type_product_stock;
        $timeout (function() {
            $scope.showPrint = false;
        }, 1200);

        });    

    }
    else if(user.state == "preggo")
    {
        dashboardModel.getGraphSpec_Preggo(year).success(function(data){

        $scope.data_po_graph = data.adjudications;
        var names = [];
        var values = [];
        var atraso = [];
        $scope.active_PO = 0;
        $scope.final_PO = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.adjudications.length; i++) {
        var obj = data.adjudications[i];

        if(obj.specifications != undefined) {
            names.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values.push(obj.total);
            $scope.active_PO += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PO += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
             //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }

            }
            
        }
        }
        media_atraso = media_atraso/$scope.active_PO;
        $scope.final_atraso = final_atraso / data.adjudications.length;
        $scope.media_atraso = media_atraso;
        $scope.labels = names;
        $scope.data = values;
        $scope.atraso_arr = atraso;
        $scope.atraso = data.delay;
        $scope.avg_entrega = data.avg_entrega;
        $scope.avg_atraso = data.avg_atraso;

        });

        /* Construct graph type of specs */
        /* Construct graph Peças Quantidade */
        dashboardModel.getGraphTypeProductSum_Preggo(year).success(function(data){

        $scope.data_pecas_qtnd_graph = data;
        var names_type_product_sum = [];
        var values_type_product_sum = [];
        $scope.pecas_QUANTIDADE = 0;
        $scope.final_QTND = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_sum.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_sum.push(obj.total);
            $scope.pecas_QUANTIDADE += Number(obj.total);
        }
        if(obj.valor != undefined) {
            $scope.final_QTND += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
             //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
           
        }
        }
        media_atraso = media_atraso/$scope.pecas_QUANTIDADE;
        $scope.final_atraso_type = final_atraso / data.length;
        $scope.media_atraso_type = media_atraso;
         $scope.labels_type_product_sum = names_type_product_sum;
         $scope.data_type_product_sum = values_type_product_sum;

        });

        /* 2nd PHASE DASHBOARD */
        dashboardModel.getGraphTotalCliente_Preggo(year).success(function(data){

        $scope.data_total_cliente_LEAD = data;
        var names_total_cliente = [];
        var values_total_cliente = [];
        $scope.pecas_total_cliente = 0;
        $scope.final_valor_cliente = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_cliente.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_cliente.push(obj.total);
            $scope.pecas_total_cliente += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_cliente += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }

        }
        }
        $scope.final_atraso_cliente = final_atraso / data.length;
        $scope.media_atraso_cliente = media_atraso / $scope.pecas_total_cliente;
         $scope.labels_total_cliente = names_total_cliente;
         $scope.data_total_cliente = values_total_cliente;

        });
        dashboardModel.getGraphTypeProduct_Preggo(year).success(function(data){

        $scope.data_pecas_graph = data;
        var names_type_product = [];
        var values_type_product = [];
        $scope.pecas_CLIENTE = 0;
        $scope.final_PECA = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product.push(obj.total);
            $scope.pecas_CLIENTE += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
        }
        }
        $scope.final_atraso_type_cliente = final_atraso / data.length;
        $scope.media_atraso_type_cliente = media_atraso / $scope.pecas_CLIENTE;
         $scope.labels_type_product = names_type_product;
         $scope.data_type_product = values_type_product;

        });

        /* 3rd PHASE DASHBOARD */
        dashboardModel.getGraphTotalStock_Preggo(year).success(function(data){
        $scope.data_total_stock_LEAD = data;
        var names_total_stock = [];
        var values_total_stock = [];
        $scope.pecas_total_stock = 0;
        $scope.final_valor_stock = 0;
         var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_stock.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_stock.push(obj.total);
            $scope.pecas_total_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_stock += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
        }
        }
        $scope.final_atraso_stock = final_atraso / data.length;
        $scope.media_atraso_stock = media_atraso / $scope.pecas_total_stock;
         $scope.labels_total_stock = names_total_stock;
         $scope.data_total_stock = values_total_stock;

        });

        dashboardModel.getGraphTypeProductStock_Preggo(year).success(function(data){
        $scope.data_pecas_graph_stock = data;
        var names_type_product_stock = [];
        var values_type_product_stock = [];
        $scope.pecas_CLIENTE_stock = 0;
        $scope.final_PECA_stock = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_stock.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_stock.push(obj.total);
            $scope.pecas_CLIENTE_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA_stock += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
        }
        }
        $scope.final_atraso_type_stock = final_atraso / data.length;
        $scope.media_atraso_type_stock = media_atraso / $scope.pecas_CLIENTE_stock;
         $scope.labels_type_product_stock = names_type_product_stock;
         $scope.data_type_product_stock = values_type_product_stock;
          $timeout (function() {
            $scope.showPrint = false;
        }, 1200);

        });

    }
    else
    {

    dashboardModel.getGraphSpec(year, local).success(function(data){

        $scope.data_po_graph = data.adjudications;
        var names = [];
        var values = [];
        $scope.active_PO = 0;
        $scope.final_PO = 0;

        for(var i = 0; i < data.adjudications.length; i++) {
        var obj = data.adjudications[i];

        if(obj.specifications != undefined) {
            names.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values.push(obj.total);
            $scope.active_PO += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PO += obj.valor;
        }
        }
        $scope.labels = names;
        $scope.data = values;
        $scope.atraso = data.delay;
        $scope.avg_entrega = data.avg_entrega;
        $scope.avg_atraso = data.avg_atraso;

    });

    /* Construct graph type of specs */
    /* Construct graph Peças Quantidade */
    dashboardModel.getGraphTypeProductSum(year, local).success(function(data){

        $scope.data_pecas_qtnd_graph = data;
        var names_type_product_sum = [];
        var values_type_product_sum = [];
        $scope.pecas_QUANTIDADE = 0;
        $scope.final_QTND = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_sum.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_sum.push(obj.total);
            $scope.pecas_QUANTIDADE += Number(obj.total);
        }
        if(obj.valor != undefined) {
            $scope.final_QTND += obj.valor;
        }
        }
         $scope.labels_type_product_sum = names_type_product_sum;
         $scope.data_type_product_sum = values_type_product_sum;

    });

    /* 2nd PHASE DASHBOARD */
    dashboardModel.getGraphTotalCliente(year, local).success(function(data){

        $scope.data_total_cliente_LEAD = data;
        var names_total_cliente = [];
        var values_total_cliente = [];
        $scope.pecas_total_cliente = 0;
        $scope.final_valor_cliente = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_cliente.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_cliente.push(obj.total);
            $scope.pecas_total_cliente += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_cliente += obj.valor;
        }
        }
         $scope.labels_total_cliente = names_total_cliente;
         $scope.data_total_cliente = values_total_cliente;

    });
    dashboardModel.getGraphTypeProduct(year, local).success(function(data){

        $scope.data_pecas_graph = data;
        var names_type_product = [];
        var values_type_product = [];
        $scope.pecas_CLIENTE = 0;
        $scope.final_PECA = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product.push(obj.total);
            $scope.pecas_CLIENTE += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA += obj.valor;
        }
        }
         $scope.labels_type_product = names_type_product;
         $scope.data_type_product = values_type_product;

    });

    /* 3rd PHASE DASHBOARD */
    dashboardModel.getGraphTotalStock(year, local).success(function(data){
        $scope.data_total_stock_LEAD = data;
        var names_total_stock = [];
        var values_total_stock = [];
        $scope.pecas_total_stock = 0;
        $scope.final_valor_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_stock.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_stock.push(obj.total);
            $scope.pecas_total_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_stock += obj.valor;
        }
        }
         $scope.labels_total_stock = names_total_stock;
         $scope.data_total_stock = values_total_stock;

    });

    dashboardModel.getGraphTypeProductStock(year, local).success(function(data){
        $scope.data_pecas_graph_stock = data;
        var names_type_product_stock = [];
        var values_type_product_stock = [];
        $scope.pecas_CLIENTE_stock = 0;
        $scope.final_PECA_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_stock.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_stock.push(obj.total);
            $scope.pecas_CLIENTE_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA_stock += obj.valor;
        }
        }
         $scope.labels_type_product_stock = names_type_product_stock;
         $scope.data_type_product_stock = values_type_product_stock;
          $timeout (function() {
            $scope.showPrint = false;
        }, 1200);
        });
    }
    
	
    //FUNCTION RELOAD THE PAGE OF PREGGO
    function reload_preggo(year) {
        dashboardModel.getGraphSpec_Preggo(year).success(function(data){

        $scope.data_po_graph = data.adjudications;
        var names = [];
        var values = [];
        var atraso = [];
        $scope.active_PO = 0;
        $scope.final_PO = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.adjudications.length; i++) {
        var obj = data.adjudications[i];

        if(obj.specifications != undefined) {
            names.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values.push(obj.total);
            $scope.active_PO += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PO += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
             //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }  
        }
        }
        media_atraso = media_atraso/$scope.active_PO;
        $scope.final_atraso = final_atraso / data.adjudications.length;
        $scope.media_atraso = media_atraso;
        $scope.labels = names;
        $scope.data = values;
        $scope.atraso_arr = atraso;
        $scope.atraso = data.delay;
        $scope.avg_entrega = data.avg_entrega;
        $scope.avg_atraso = data.avg_atraso;

        });

        /* Construct graph type of specs */
        /* Construct graph Peças Quantidade */
        dashboardModel.getGraphTypeProductSum_Preggo(year).success(function(data){

        $scope.data_pecas_qtnd_graph = data;
        var names_type_product_sum = [];
        var values_type_product_sum = [];
        $scope.pecas_QUANTIDADE = 0;
        $scope.final_QTND = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_sum.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_sum.push(obj.total);
            $scope.pecas_QUANTIDADE += Number(obj.total);
        }
        if(obj.valor != undefined) {
            $scope.final_QTND += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
             //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
           
        }
        }
        media_atraso = media_atraso/$scope.pecas_QUANTIDADE;
        $scope.final_atraso_type = final_atraso / data.length;
        $scope.media_atraso_type = media_atraso;
         $scope.labels_type_product_sum = names_type_product_sum;
         $scope.data_type_product_sum = values_type_product_sum;

        });

        /* 2nd PHASE DASHBOARD */
        dashboardModel.getGraphTotalCliente_Preggo(year).success(function(data){

        $scope.data_total_cliente_LEAD = data;
        var names_total_cliente = [];
        var values_total_cliente = [];
        $scope.pecas_total_cliente = 0;
        $scope.final_valor_cliente = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_cliente.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_cliente.push(obj.total);
            $scope.pecas_total_cliente += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_cliente += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }

        }
        }
        $scope.final_atraso_cliente = final_atraso / data.length;
        $scope.media_atraso_cliente = media_atraso / $scope.pecas_total_cliente;
         $scope.labels_total_cliente = names_total_cliente;
         $scope.data_total_cliente = values_total_cliente;

        });
        dashboardModel.getGraphTypeProduct_Preggo(year).success(function(data){

        $scope.data_pecas_graph = data;
        var names_type_product = [];
        var values_type_product = [];
        $scope.pecas_CLIENTE = 0;
        $scope.final_PECA = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product.push(obj.total);
            $scope.pecas_CLIENTE += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
        }
        }
        $scope.final_atraso_type_cliente = final_atraso / data.length;
        $scope.media_atraso_type_cliente = media_atraso / $scope.pecas_CLIENTE;
         $scope.labels_type_product = names_type_product;
         $scope.data_type_product = values_type_product;

        });

        /* 3rd PHASE DASHBOARD */
        dashboardModel.getGraphTotalStock_Preggo(year).success(function(data){
        $scope.data_total_stock_LEAD = data;
        var names_total_stock = [];
        var values_total_stock = [];
        $scope.pecas_total_stock = 0;
        $scope.final_valor_stock = 0;
         var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_stock.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_stock.push(obj.total);
            $scope.pecas_total_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_stock += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
        }
        }
        $scope.final_atraso_stock = final_atraso / data.length;
        $scope.media_atraso_stock = media_atraso / $scope.pecas_total_stock;
         $scope.labels_total_stock = names_total_stock;
         $scope.data_total_stock = values_total_stock;

        });

        dashboardModel.getGraphTypeProductStock_Preggo(year).success(function(data){
        $scope.data_pecas_graph_stock = data;
        var names_type_product_stock = [];
        var values_type_product_stock = [];
        $scope.pecas_CLIENTE_stock = 0;
        $scope.final_PECA_stock = 0;
        var final_atraso = 0;
        var media_atraso = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_stock.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_stock.push(obj.total);
            $scope.pecas_CLIENTE_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA_stock += obj.valor;
        }
        if(obj.atraso != undefined) {
            final_atraso += obj.atraso;
            //FORMULA PARA CALCULAR PENALIZAçÂO
            for(var i_c = 1; i_c <= obj.atraso; i_c++) {
                if(obj.atraso <= 3) {
                    media_atraso += 1;
                }
                else(obj.atraso > 3)
                {
                    media_atraso += 2;
                }
            }
        }
        }
        $scope.final_atraso_type_stock = final_atraso / data.length;
        $scope.media_atraso_type_stock = media_atraso / $scope.pecas_CLIENTE_stock;
         $scope.labels_type_product_stock = names_type_product_stock;
         $scope.data_type_product_stock = values_type_product_stock;
          $timeout (function() {
            $scope.showPrint = false;
        }, 1200);

        });
    }

    //FUNCTIONS TO RELOAD THE PAGE
    function reload(year, local, sigla) {
    if(user.state == "vendedor")
    {

    dashboardModel.getGraphSpec_Vendedor(year, local, sigla).success(function(data){

        $scope.data_po_graph = data.adjudications;
        var names = [];
        var values = [];
        $scope.active_PO = 0;
        $scope.final_PO = 0;

        for(var i = 0; i < data.adjudications.length; i++) {
        var obj = data.adjudications[i];

        if(obj.specifications != undefined) {
            names.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values.push(obj.total);
            $scope.active_PO += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PO += obj.valor;
        }
        }
        $scope.labels = names;
        $scope.data = values;
        $scope.atraso = data.delay;
        $scope.avg_entrega = data.avg_entrega;
        $scope.avg_atraso = data.avg_atraso;

    });

    /* Construct graph type of specs */
    /* Construct graph Peças Quantidade */
    dashboardModel.getGraphTypeProductSum_Vendedor(year, local, sigla).success(function(data){

        $scope.data_pecas_qtnd_graph = data;
        var names_type_product_sum = [];
        var values_type_product_sum = [];
        $scope.pecas_QUANTIDADE = 0;
        $scope.final_QTND = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_sum.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_sum.push(obj.total);
            $scope.pecas_QUANTIDADE += Number(obj.total);
        }
        if(obj.valor != undefined) {
            $scope.final_QTND += obj.valor;
        }
        }
         $scope.labels_type_product_sum = names_type_product_sum;
         $scope.data_type_product_sum = values_type_product_sum;

    });

    /* 2nd PHASE DASHBOARD */
    dashboardModel.getGraphTotalCliente_Vendedor(year, local, sigla).success(function(data){

        $scope.data_total_cliente_LEAD = data;
        var names_total_cliente = [];
        var values_total_cliente = [];
        $scope.pecas_total_cliente = 0;
        $scope.final_valor_cliente = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_cliente.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_cliente.push(obj.total);
            $scope.pecas_total_cliente += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_cliente += obj.valor;
        }
        }
         $scope.labels_total_cliente = names_total_cliente;
         $scope.data_total_cliente = values_total_cliente;

    });
    dashboardModel.getGraphTypeProduct_Vendedor(year, local, sigla).success(function(data){

        $scope.data_pecas_graph = data;
        var names_type_product = [];
        var values_type_product = [];
        $scope.pecas_CLIENTE = 0;
        $scope.final_PECA = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product.push(obj.total);
            $scope.pecas_CLIENTE += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA += obj.valor;
        }
        }
         $scope.labels_type_product = names_type_product;
         $scope.data_type_product = values_type_product;

    });

    /* 3rd PHASE DASHBOARD */
    dashboardModel.getGraphTotalStock_Vendedor(year, local, sigla).success(function(data){
        $scope.data_total_stock_LEAD = data;
        var names_total_stock = [];
        var values_total_stock = [];
        $scope.pecas_total_stock = 0;
        $scope.final_valor_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_stock.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_stock.push(obj.total);
            $scope.pecas_total_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_stock += obj.valor;
        }
        }
         $scope.labels_total_stock = names_total_stock;
         $scope.data_total_stock = values_total_stock;

    });

    dashboardModel.getGraphTypeProductStock_Vendedor(year, local, sigla).success(function(data){
        $scope.data_pecas_graph_stock = data;
        var names_type_product_stock = [];
        var values_type_product_stock = [];
        $scope.pecas_CLIENTE_stock = 0;
        $scope.final_PECA_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_stock.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_stock.push(obj.total);
            $scope.pecas_CLIENTE_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA_stock += obj.valor;
        }
        }
         $scope.labels_type_product_stock = names_type_product_stock;
         $scope.data_type_product_stock = values_type_product_stock;
          $timeout (function() {
            $scope.showPrint = false;
        }, 1200);

        });    

    }
    else
    {

    dashboardModel.getGraphSpec(year, local).success(function(data){

        $scope.data_po_graph = data.adjudications;
        var names = [];
        var values = [];
        $scope.active_PO = 0;
        $scope.final_PO = 0;

        for(var i = 0; i < data.adjudications.length; i++) {
        var obj = data.adjudications[i];

        if(obj.specifications != undefined) {
            names.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values.push(obj.total);
            $scope.active_PO += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PO += obj.valor;
        }
        }
        $scope.labels = names;
        $scope.data = values;
        $scope.atraso = data.delay;
        $scope.avg_entrega = data.avg_entrega;
        $scope.avg_atraso = data.avg_atraso;

    });

    /* Construct graph type of specs */
    /* Construct graph Peças Quantidade */
    dashboardModel.getGraphTypeProductSum(year, local).success(function(data){

        $scope.data_pecas_qtnd_graph = data;
        var names_type_product_sum = [];
        var values_type_product_sum = [];
        $scope.pecas_QUANTIDADE = 0;
        $scope.final_QTND = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_sum.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_sum.push(obj.total);
            $scope.pecas_QUANTIDADE += Number(obj.total);
        }
        if(obj.valor != undefined) {
            $scope.final_QTND += obj.valor;
        }
        }
         $scope.labels_type_product_sum = names_type_product_sum;
         $scope.data_type_product_sum = values_type_product_sum;

    });

    /* 2nd PHASE DASHBOARD */
    dashboardModel.getGraphTotalCliente(year, local).success(function(data){

        $scope.data_total_cliente_LEAD = data;
        var names_total_cliente = [];
        var values_total_cliente = [];
        $scope.pecas_total_cliente = 0;
        $scope.final_valor_cliente = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_cliente.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_cliente.push(obj.total);
            $scope.pecas_total_cliente += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_cliente += obj.valor;
        }
        }
         $scope.labels_total_cliente = names_total_cliente;
         $scope.data_total_cliente = values_total_cliente;

    });
    dashboardModel.getGraphTypeProduct(year, local).success(function(data){

        $scope.data_pecas_graph = data;
        var names_type_product = [];
        var values_type_product = [];
        $scope.pecas_CLIENTE = 0;
        $scope.final_PECA = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product.push(obj.total);
            $scope.pecas_CLIENTE += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA += obj.valor;
        }
        }
         $scope.labels_type_product = names_type_product;
         $scope.data_type_product = values_type_product;

    });

    /* 3rd PHASE DASHBOARD */
    dashboardModel.getGraphTotalStock(year, local).success(function(data){
        $scope.data_total_stock_LEAD = data;
        var names_total_stock = [];
        var values_total_stock = [];
        $scope.pecas_total_stock = 0;
        $scope.final_valor_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.specifications != undefined) {
            names_total_stock.push(obj.specifications);
        }
        if(obj.total != undefined) {
            values_total_stock.push(obj.total);
            $scope.pecas_total_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_valor_stock += obj.valor;
        }
        }
         $scope.labels_total_stock = names_total_stock;
         $scope.data_total_stock = values_total_stock;

    });

    dashboardModel.getGraphTypeProductStock(year, local).success(function(data){
        $scope.data_pecas_graph_stock = data;
        var names_type_product_stock = [];
        var values_type_product_stock = [];
        $scope.pecas_CLIENTE_stock = 0;
        $scope.final_PECA_stock = 0;

        for(var i = 0; i < data.length; i++) {
        var obj = data[i];

        if(obj.tipo_acabamento != undefined) {
            names_type_product_stock.push(obj.tipo_acabamento);
        }
        if(obj.total != undefined) {
            values_type_product_stock.push(obj.total);
            $scope.pecas_CLIENTE_stock += obj.total;
        }
        if(obj.valor != undefined) {
            $scope.final_PECA_stock += obj.valor;
        }
        }
         $scope.labels_type_product_stock = names_type_product_stock;
         $scope.data_type_product_stock = values_type_product_stock;
         $timeout (function() {
            $scope.showPrint = false;
        }, 1200);

        });
    }
    }


	/*Variables */
	angular.extend($scope, {
        changeYear : "" + year + "",
        changeLocal : "Total",
        sigla: user.sigla,
        showPrint: true,
	});

	/* Functions */
	angular.extend($scope, {
		changeData: function(year, local, sigla) {
            $scope.showPrint = true;
            reload(year, local, sigla);
        },
        changeData_Preggo: function(year) {
            reload_preggo(year);
        },
        calculate_Penalizacao: function(value, total) {
            var sum = 0;
            for(var i = 1; i <= value; i++) {
                if(value <= 3) {
                    sum += 1;
                }
                else if(value > 3)
                {
                    sum += 2;
                }
            }

            return Math.round(sum/total * 100) / 100;
        },
       formatNumber : function(i) {
            return Math.round(i * 100)/100; 
        },
     
        PrintPDF: function () {
        //SECTION 1 ELEMENTS
        var table_1=document.getElementById("table_1");
        var canvas = document.getElementById("pie_1");
        var canvas_img  = canvas.toDataURL("image/png");
         //SECTION 2 ELEMENTS
        var table_2=document.getElementById("table_2");
        var canvas_2 = document.getElementById("pie_2");
        var canvas_img2  = canvas_2.toDataURL("image/png");
        //SECTION 3 ELEMENTS
        var table_3=document.getElementById("table_3");
        var canvas_3 = document.getElementById("pie_3");
        var canvas_img3  = canvas_3.toDataURL("image/png");
        //SECTION 4 ELEMENTS
        var table_4=document.getElementById("table_4");
        var canvas_4 = document.getElementById("pie_4");
        var canvas_img4  = canvas_4.toDataURL("image/png");
        //SECTION 5 ELEMENTS
        var table_5=document.getElementById("table_5");
        var canvas_5 = document.getElementById("pie_5");
        var canvas_img5  = canvas_5.toDataURL("image/png");
        //SECTION 6 ELEMENTS
        var table_6=document.getElementById("table_6");
        var canvas_6 = document.getElementById("pie_6");
        var canvas_img6  = canvas_6.toDataURL("image/png");
        //GLOBAL VARIABLES
        var local = $('#local-me').text();
        var num_atraso = $('#num_atraso').text();
        var num_avg_entrega = $('#num_avg_entrega').text();
        var num_avg_atraso = $('#num_avg_atraso').text();

        newWin= window.open("");
        newWin.document.write('<html>');
        newWin.document.write('<head><link rel="stylesheet" type="text/css" href="' + baseUrl + 'css/print-table.css"></head>');
        newWin.document.write('<body>');
        newWin.document.write('<div style="text-align:center; width: 100%"> <img src="' + baseUrl +'images/logo.png" width="200" height="30" /></div>');
        newWin.document.write('<div style="text-align:center; width: 90%;margin-left: 5%; margin-right: 5%; margin-top: 25px; padding-bottom: 5px; border-bottom: 1px solid #ddd"><span class="title-dashboard">' + local + '</span> </div>');
        newWin.document.write('<div class="geral-info" style="text-align:center; width: 90%;margin-left: 5%; margin-right: 5%; margin-top: 25px; padding-bottom: 5px; border-bottom: 1px solid #ddd">Em atraso: <b><i>' + num_atraso +'</b></i>&nbsp;&nbsp;&nbsp;&nbsp; Média Entrega: <b><i>' + num_avg_entrega +'</b></i>&nbsp;&nbsp;&nbsp;&nbsp; Média Atraso: <b><i>' + num_avg_atraso +'</b></i></div>');
        //newWin.document.write('<div class="col-md-8 col-sm-8 col-xs-12"><img src="' + canvas_img +'" width="250" height="150" /></div>')
        newWin.document.write('<div class="section-base"> <div class="table-base">' + table_1.outerHTML + '</div><div class="graph-base"><img src="' + canvas_img +'" width="275" height="138" /></div></div>');
        newWin.document.write('<div class="section-base" style="margin-bottom: 25px"> <div class="table-base">' + table_2.outerHTML + '</div><div class="graph-base"><img src="' + canvas_img2 +'" width="275" height="138" /></div></div>');
        //2nd SECTION
        newWin.document.write('<div style="text-align:center; width: 90%;margin-left: 5%; margin-right: 5%; padding-top: 40px !important; padding-bottom: 5px; border-bottom: 1px solid #ddd"><span class="title-dashboard"> Clientes </span> </div>');
        newWin.document.write('<div class="section-base"> <div class="table-base">' + table_3.outerHTML + '</div><div class="graph-base"><img src="' + canvas_img3 +'" width="275" height="138" /></div></div>');
        //PAGEBREAK
        newWin.document.write('<div class="pagebreak"> </div>');
        newWin.document.write('<div style="text-align:center; width: 100%"> <img src="' + baseUrl +'images/logo.png" width="200" height="30" /></div>');
        newWin.document.write('<div class="section-base" style="margin-top: 40px; margin-bottom: 25px"> <div class="table-base">' + table_4.outerHTML + '</div><div class="graph-base"><img src="' + canvas_img4 +'" width="275" height="138" /></div></div>');
        //3rd SECTION
        newWin.document.write('<div style="text-align:center; width: 90%;margin-left: 5%; margin-right: 5%; padding-top: 40px !important; padding-bottom: 5px; border-bottom: 1px solid #ddd"><span class="title-dashboard"> Stock </span> </div>');
        newWin.document.write('<div class="section-base"> <div class="table-base">' + table_5.outerHTML + '</div><div class="graph-base"><img src="' + canvas_img5 +'" width="275" height="138" /></div></div>');
        newWin.document.write('<div class="section-base"> <div class="table-base">' + table_6.outerHTML + '</div><div class="graph-base"><img src="' + canvas_img6 +'" width="275" height="138" /></div></div>');
        newWin.document.write('</body></html>');

        //GET THE BROWSER 
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isSafari == true) {
            $(newWin).ready(function() {  
         setTimeout(
            function(){
            newWin.print();
            newWin.close();
            },(300));
        });
        }
        else if(isFirefox == true){
         newWin.print();
         newWin.close();
        }
        else if(isIE == true) {
            newWin.print();
        }
        else if(isChrome == true){
            $(newWin).ready(function() {  
                setTimeout(
                    function(){
                    newWin.print();
                    newWin.close();
                },(300));
            });
        }
        },



	});

}]);