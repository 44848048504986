myApp.controller('spvUniqueController', ['$scope', '$cookies', 'costumerModel', 'spvModel', '$uibModal', 'Notification', '$route', '$routeParams',
	function ($scope, $cookies, costumerModel, spvModel, $uibModal, Notification, $route, $routeParams) {
	
	var user = $cookies.getObject('auth');

	$scope.user_state = user.state;
	$scope.user_email = user.email;
	$scope.email_permited = 'tiagoreis@bocadolobo.com';

	/* GET CRs */
	costumerModel.getCostumers().success(function(response){
		$scope.costumers = response;
		$scope.showCostumers = true;

	});


	spvModel.getUniqueCase($routeParams.case).success(function(response){
		$scope.Case = response;
		$scope.Case_files = response.files;
		$scope.Case_quiz = response.quiz;
		$scope.Case_quiz_curso = response.quiz_curso;
	});

	

	/*Variables */
	angular.extend($scope, {
		today: new Date(),
		dropzoneConfig: {
			 'options': { // passed into the Dropzone constructor
      		'url': baseUrl + 'spv/file-upload',
      		autoProcessQueue: false,
      		addRemoveLinks: true,
      		init: function(){
      			//save the dropzone to a global variable
				$scope.dz = this;
			}
    		},
    		'eventHandlers': {
      		'sending': function (file, xhr, formData) {
      			formData.append('_token', csrfToken);
      			formData.append('reclamationId', $routeParams.case);
      		},
      		'success': function (file, response) {
      			//$scope.singleGallery.images.push(response);
      			//$scope.$emit('imageAdded', $scope.singleGallery);
      		}
    		}
		}
	
	});



	/* Functions */
	angular.extend($scope, {
		UpdateReclamation: function (editReclamation) {
		spvModel.UpdateReclamation($scope.Case, $routeParams.case).success(function(){
			if($scope.dz.getQueuedFiles().length >= 1)
			{
				$scope.dz.processQueue();
				$scope.dz.on("complete", function (file) {
                 if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {

					Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; Detalhes Gerais Atualizados');
					$route.reload();

                 }
             	});
			}
			else
			{
				Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; Detalhes Gerais Atualizados');
				$route.reload()
			}
		});
			
		},
		UpdateReclamation_Phase2: function() {
			spvModel.UpdateReclamation_2($scope.Case_quiz, $routeParams.case).success(function(){

				Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; Detalhes Fase Análise Atualizados');

		});
		},
		UpdateReclamation_Phase3: function() {
			spvModel.UpdateReclamation_3($scope.Case_quiz_curso, $routeParams.case).success(function(){
				Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; Detalhes Fase Curso Atualizados');

		});
		},
		removeFile: function (id, file) {
			if (confirm("Tem certeza que pretende eliminar o ficheiro do servidor?")) {
			spvModel.deleteFile(id).success(function(){
				var index = $scope.Case_files.indexOf(file);
        			if (index !== -1) {
            		$scope.Case_files.splice(index, 1);
        			}
				});
			}
		},
	

	});

}]);