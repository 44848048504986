myApp.controller('spvPhase2Controller', ['$scope', '$cookies', 'spvModel', '$uibModal', 'Notification', '$route',
	function ($scope, $cookies, spvModel, $uibModal, Notification, $route) {
	
	var user = $cookies.getObject('auth');

	$scope.user_state = user.state;
	$scope.user_email = user.email;
	$scope.email_permited = 'tiagoreis@bocadolobo.com';

	spvModel.getReclamations().success(function(response){
		$scope.Reclamations = response;
		$scope.showReclamations = true;

	});
	//GET NUMBER OF RECLAMATIONS 
	spvModel.getAnaliseCount().success(function(response){
		$scope.AnaliseCount = response;
	});
	spvModel.getCursoCount().success(function(response){
		$scope.CursoCount = response;
	});
	spvModel.getReprovedCount().success(function(response){
		$scope.ReprovedCount = response;
	});

	/*Variables */
	angular.extend($scope, {
		today: new Date(),
		
	
	});

	function refreshData() {
		spvModel.getReclamations().success(function(response){
		$scope.Reclamations = response;
		});
		spvModel.getAnaliseCount().success(function(response){
		$scope.AnaliseCount = response;
		});
		spvModel.getCursoCount().success(function(response){
		$scope.CursoCount = response;
		});
		spvModel.getReprovedCount().success(function(response){
		$scope.ReprovedCount = response;
		});

	}
	function refreshData_extend() {
		spvModel.getReclamations().success(function(response){
		angular.extend($scope.Reclamations, response);
		});
	}

	/* Functions */
	angular.extend($scope, {
		changeToggleValue: function (value, id, type) {
			spvModel.updateValue(value, id, type);
		},
		changeTextComment: function(value, id, type) {
			spvModel.updateComment(value, id, type);
		},
		Aprovar: function(id) {
		if (confirm("Irá Aprovar o Caso selecionado, tem a certeza?")) {
			spvModel.Aprovar_phase2(id).success(function(){
				refreshData();
			});
		}
		},
		Reprovar: function(id) {
		if (confirm("Irá Reprovar o Caso selecionado, tem a certeza?")) {
			spvModel.Reprovar_phase2(id).success(function(){
				refreshData();
			});
		}
		},
		deleteCase: function(id, row) {
			if (confirm("Irá Eliminar o Caso permanentemente, tem a certeza?")) {
				spvModel.Eliminar_Caso(id).success(function(){
					var index = $scope.Reclamations.indexOf(row);
        			if (index !== -1) {
            		$scope.Reclamations.splice(index, 1);
        			}

        			spvModel.getAnaliseCount().success(function(response){
						$scope.AnaliseCount = response;
					});
					spvModel.getCursoCount().success(function(response){
						$scope.CursoCount = response;
					});
					spvModel.getReprovedCount().success(function(response){
						$scope.ReprovedCount = response;
					});
				});

			}
		},
	



		// ##### MODALS ###### //
		openViewFiles : function (id) {
  			$scope.item = id;

    		var modalInstance = $uibModal.open({
      		animation: $scope.animationsEnabled,
      		templateUrl: 'templates/modals/spv_view_files.html',
      		controller: 'modalControllerViewFiles',
      		resolve: {
        		item: function () {
          		return $scope.item;
        		}
      		}
    		});
		},
	

	});

}]);