myApp.controller('spvController', ['$scope', '$cookies', 'costumerModel', 'spvModel', '$uibModal', 'Notification', '$route',
	function ($scope, $cookies, costumerModel, spvModel, $uibModal, Notification, $route) {
	
	var user = $cookies.getObject('auth');

	/* GET CRs */
	costumerModel.getCostumers().success(function(response){
		$scope.costumers = response;
		$scope.showCostumers = true;

	});

	//GET LAST ID TO INCREMENTS
	spvModel.getLastID().success(function(response){
		$scope.last_id = response + 1;
	});

	//GET NUMBER OF RECLAMATIONS 
	spvModel.getAnaliseCount().success(function(response){
		$scope.AnaliseCount = response;
	});
	spvModel.getCursoCount().success(function(response){
		$scope.CursoCount = response;
	});
	spvModel.getReprovedCount().success(function(response){
		$scope.ReprovedCount = response;
	});
	
	/*Variables */
	angular.extend($scope, {
		today: new Date(),
		newReclamation: {Date:new Date(), Transporte_envio: 'Marca', Reincidente: 0, Disposto_negociar: 0, Existe_stock: 0},
		dropzoneConfig: {
			 'options': { // passed into the Dropzone constructor
      		'url': baseUrl + 'spv/file-upload',
      		autoProcessQueue: false,
      		addRemoveLinks: true,
      		init: function(){
      			//save the dropzone to a global variable
				$scope.dz = this;
			}
    		},
    		'eventHandlers': {
      		'sending': function (file, xhr, formData) {
      			formData.append('_token', csrfToken);
      			formData.append('reclamationId', $scope.last_id);
      		},
      		'success': function (file, response) {
      			//$scope.singleGallery.images.push(response);
      			//$scope.$emit('imageAdded', $scope.singleGallery);
      		}
    		}
		}
	
	});



	/* Functions */
	angular.extend($scope, {
		saveNewReclamation: function (addReclamation) {
		spvModel.saveReclamation($scope.newReclamation, $scope.last_id).success(function(){
			if($scope.dz.getQueuedFiles().length >= 1)
			{
				$scope.dz.processQueue();
				$scope.dz.on("complete", function (file) {
                 if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {

					Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; Reclamação Inserida');
					$route.reload();

	
                 }
             	});
			}
			else
			{
				Notification.success('<span class="glyphicon glyphicon-ok"></span> &nbsp; Reclamação Inserida');
				$route.reload()
			}
		});
			
		},
	

	});

}]);