myApp.controller('queueController', ['$scope', 'queueModel', '$cookies', 'orderModel', '$routeParams', '$uibModal', 
	function ($scope, queueModel, $cookies, orderModel, $routeParams, $uibModal) {
	
	var user = $cookies.getObject('auth');

	queueModel.getAdjudicationsQueue().success(function(response){
	$scope.Adjudications = response;
	$scope.showAdjudications = true;

	});

	/*Variables */
	angular.extend($scope, {
		newProvider: {},
		today: new Date()
	
	});

	function refreshData() {
	queueModel.getAdjudicationsQueue().success(function(response){
	$scope.Adjudications = response;

	});
	}

	/* Functions */
	angular.extend($scope, {

	defineClass: function(type) {

	switch (true) {
		case (type == "atraso"):
		return "danger_apply_calendar_absolute";
		break;

		case (type == "valor_diferente"):
		return "yellow_apply_calendar_absolute";
		break;

		case (type == "both"):
		return "blue_apply_calendar_absolute";
		break;

		}
	},
	
	calculateECF: function(param1, param2, param3, param4){
			valor_ecf = param1 * param2;

			valor_parcial = valor_ecf - (param3 / 100 * valor_ecf);
			valor_parcial_final = valor_parcial - (param4 / 100 * valor_parcial);

			return Math.round(valor_parcial_final * 100) / 100;
	},
	calculatePenalizacao: function(week_entrada, lt_preggo, ent_Armazem, valor_ecf) {
	week_encomenda = week_entrada + lt_preggo;

	//CALCULATE WEEK NUMBER BASED ON DATE OF ENT_ARMAZEM
	d = new Date(ent_Armazem);
    d.setHours(0,0,0);
    d.setDate(d.getDate()+4-(d.getDay()||7));
    week_armazem =  Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);

    semanas_atraso = week_armazem - week_encomenda;

    //FUNCTION TO CALCULATE THE PERCENTAGE OF PENALTY
    var desconto = 0;
	for(var i = 1; i <= semanas_atraso; i++) {
		if(semanas_atraso <= 3) {
			desconto += 1;
		}
		else if(semanas_atraso > 3)
		{
			desconto += 2;
		}
	}

	valor_parcial = valor_ecf - (desconto / 100 * valor_ecf);
	return Math.round(valor_parcial * 100) / 100;


	},
	calculateDiferencial: function(faturado, valor_penalizacao) {
		valor_parcial = valor_penalizacao - faturado;

		return Math.round(valor_parcial * 100) / 100;
	},

	DefinePick: function(param) {
	if(param == null)
	{
		return 0;
	}
	else
	{
		return param;
	}
	},
	saveNotaCredito: function(value, id, type) {
	queueModel.NotaCredito(value, id, user.name, type).success(function(response) {
		refreshData();
	})
	},
	refreshData: function() {
		refreshData();
	},

	//OPEN MODAL NEW ANOTATION
	openModalStatus : function (id) {
  		$scope.item = id;

    	var modalInstance = $uibModal.open({
      	animation: $scope.animationsEnabled,
      	templateUrl: 'templates/modals/new_anotation.html',
      	controller: 'modalControllerAnotation',
      	resolve: {
        	item: function () {
          		return $scope.item;
        	}
      	}
    	});
    	modalInstance.result.then(function () {
      		refreshData();
    	}, function () {});

		},

	});

}]);