myApp.controller('PerformanceArchiveController', ['$scope', '$location', '$cookies', 'dashboardModel', '$routeParams',
	function ($scope, $location, $cookies, dashboardModel,  $routeParams) {
	
    var user = $cookies.getObject('auth');

     $scope.user_state = user.state;
    $scope.cr = user.cr;

  

    /* GET QUERY */
	var year = new Date().getFullYear();
    


    ///----------------------------- BASE FUNCTIONS ---------------------------////

    if(user.state == "vendedor") {
       dashboardModel.getArchivePerformance_sales(user.sigla).success(function(data){
        $scope.ArchivePerformance = data;
        });
    }
    else
    {
        dashboardModel.getArchivePerformance().success(function(data){
        $scope.ArchivePerformance = data;
        });
      
    }


    //FUNCTIONS TO RELOAD THE PAGE
   


	/*Variables */
	angular.extend($scope, {
    fixedYear: year,
	});

	/* Functions */
	angular.extend($scope, {
		
        getNextWeek: function(value, value2) {
            return parseInt(value) + parseInt(value2);
        },
        basicSum: function(value1, value2) {
            var valor_parcial = value1 + value2;
            var valor_final = Math.round(valor_parcial * 100) / 100;
            var valor_final_2 = valor_final.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
            return valor_final_2.replace(".", ",");

        },



        DownloadPDF: function() {
        var divToPrint=document.getElementById("status-text");
        newWin= window.open("");
        newWin.document.write('<html>');
        newWin.document.write('<head><link rel="stylesheet" type="text/css" href="' + baseUrl + 'css/print-status-text.css"></head>');
        newWin.document.write('<body>');
        newWin.document.write('<div style="text-align:center"><img src="' + baseUrl +'images/logo.png" width="200" height="30" /></div>');
        newWin.document.write('<div class="title-page">Status Arquivo </div>');
        newWin.document.write(divToPrint.outerHTML);
        newWin.document.write('</body></html>');

        //GET THE BROWSER 
        var isFirefox = typeof InstallTrigger !== 'undefined';
        var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = !!window.chrome && !!window.chrome.webstore;
        if (isSafari == true) {
            $(newWin).ready(function() {  
         setTimeout(
            function(){
            newWin.print();
            newWin.close();
            },(300));
        });
        }
        else if(isFirefox == true){
         newWin.print();
         newWin.close();
        }
        else if(isIE == true) {
            newWin.print();
        }
        else if(isChrome == true){
            $(newWin).ready(function() {  
                setTimeout(
                    function(){
                    newWin.print();
                    newWin.close();
                },(600));
            });
        }

        }



	});

}]);