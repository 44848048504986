myApp.controller('responsavelPreggoController', ['$scope', 'responsavelModel', '$routeParams', 
	function ($scope, responsavelModel, $routeParams) {
	
	responsavelModel.getResponsavel().success(function(response){
		$scope.responsaveis = response;
		$scope.showResponsaveis = true;
	});

	/*Variables */
	angular.extend($scope, {
		newResponsavel: {}
	
	});

	/* Functions */
	angular.extend($scope, {
		//SAVE COSTUMER
		saveResponsavel: function (addResponsavel) {
			if(addResponsavel.$valid) {
				$scope.formSubmitted = false;
				responsavelModel.saveResponsavel($scope.newResponsavel).success(function(response){
					//REFRESH DATA
					$scope.responsaveis = response;
					$scope.newResponsavel={};
				});
			} else {
				$scope.formSubmitted = true;
				console.log('Error');
			}
		},
		deleteResponsavel: function(id) {
			 if (confirm("Are you sure you want to delete?")) {
        		responsavelModel.deleteResponsavel(id).success(function(response){
				$scope.responsaveis = response;	
			});
    		}
		},




	});

}]);