myApp.controller('globalController', ['$scope', '$pusher',  function($scope, $pusher) {
	$scope.global = {};
	$scope.global.navUrl = 'templates/partials/nav.html';




			//PUSHER FUNCTIONS---------------------------------------------------
		/*var presence = pusher.subscribe('user-test');
				      presence.bind('App\\Events\\SomeTest', function(data) {
				        console.log(data.teste);
		});


		var presence_penalizacao = pusher.subscribe('notification-penalizacao');
		 presence_penalizacao.bind('App\\Events\\NotaCreditoNotification', function(data) {
				 if(data.NP == "")
				  {
				      var NP_text = "sem NP";
				  }
				  else
				  {
				      var NP_text = "e NP <b>" + data.NP + "<b>";
				  }
				  Notification.error('Nota Crédito Pendente com PO: <b>' + data.PO + '</b> ' + NP_text + '</b> inserida');
				 });*/

    		 

			

	
	
	
	/*queueModel.getAdjudicationsQueueCount().success(function(response){
	$scope.count = response;
	//$scope.get_count = function()  {};


	//$timeout(function() { }, 4000)
	});*/



}]);